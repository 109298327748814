import React, {useRef, useState, useEffect} from 'react';
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import {isEmail} from "validator";
import Layout from "../MyLayout";
import {Link} from "react-router-dom";
import SideMenu from "./sideMenu";
import {UpdateUserDetails, GetUserDetails} from "../../services/account.service";

const required = (value) => {
    if (!value) {
        return (
            <span style={{display: 'block'}} className="form-inlineMessage error">
                This field is required!
            </span>
        );
    }
};
const validEmail = (value) => {
    if (!isEmail(value)) {
        return (
            <span style={{display: 'block'}} className="form-inlineMessage error">Please use a valid email address, such as user@example.com.</span>
        );
    }
};
const Profile = (props) => {
    const form = useRef();
    const checkBtn = useRef();
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [mobile, setMobile] = useState("");
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");
    useEffect(() => {
        GetUserDetails().then(
            (response) => {
                if (response && response.data) {
                    let responseData = response.data;
                    setFirstName(responseData.first_name)
                    setLastName(responseData.last_name)
                    setEmail(responseData.email)
                    setMobile(responseData.contact_number)
                }
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                setMessage(resMessage);
            }
        );
    }, [])
    const onChangeFirstName = (e) => {
        const firstname = e.target.value;
        setFirstName(firstname);
    };
    const onChangeLastName = (e) => {
        const lastname = e.target.value;
        setLastName(lastname);
    };
    const onChangeEmail = (e) => {
        const email = e.target.value;
        setEmail(email);
    };
    const onChangeMobile = (e) => {
        const mobile = e.target.value;
        setMobile(mobile);
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        setMessage("");
        setLoading(true);
        form.current.validateAll();
        if (checkBtn.current.context._errors.length === 0) {
            UpdateUserDetails({first_name: firstName, last_name: lastName, email: email, contact_number: mobile}).then(
                (response) => {
                    setMessage(response.data.message);
                    setLoading(false);
                },
                (error) => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    setMessage(resMessage);
                    setLoading(false);
                }
            );
        }
    };
    return (
        <Layout>
            <section className="breadcrumbs">
                <div className="container">
                    <div className="breadcrumb">
                        <ul>
                            <li><Link to="/">Home</Link></li>
                            <li>View Profile</li>
                        </ul>
                    </div>
                </div>
            </section>
            <section className="Account_pageMn">
                <div className="container">
                    <div className="page_heading">
                        <h1>View Profile</h1>
                    </div>
                    <div className="Account_page_wrapper">
                        <div className="account_filter_toggle">Account Menu</div>
                        <aside className="left-colmn">
                            <div className="Account_navigations">
                                <SideMenu isActive={'profile'}/>
                            </div>
                        </aside>
                        <main className="right-colmn">
                            <div className="my-account-area__content">
                                <h2>Personal Information</h2>
                                <div className="account-details-form">
                                    <Form onSubmit={handleSubmit} ref={form}>
                                        <div className="form-col2">
                                            <div className="col2">
                                                <div className="form-field">
                                                    <label className="form-label" htmlFor>First Name<span>*</span></label>
                                                    <Input
                                                        type="text"
                                                        className="form-input"
                                                        name="first_name"
                                                        placeholder="Enter First Name"
                                                        value={firstName}
                                                        onChange={onChangeFirstName}
                                                        validations={[required]}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col2">
                                                <div className="form-field">
                                                    <label className="form-label" htmlFor>Last Name<span>*</span></label>
                                                    <Input
                                                        type="text"
                                                        className="form-input"
                                                        name="last_name"
                                                        placeholder="Enter Last Name"
                                                        value={lastName}
                                                        onChange={onChangeLastName}
                                                        validations={[required]}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-col2">
                                            <div className="col2">
                                                <div className="form-field">
                                                    <label className="form-label" htmlFor>Email Address<span>*</span></label>
                                                    <Input
                                                        type="text"
                                                        className="form-input"
                                                        name="email"
                                                        value={email}
                                                        onChange={onChangeEmail}
                                                        placeholder="Email"
                                                        validations={[required, validEmail]}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col2">
                                                <div className="form-field">
                                                    <label className="form-label" htmlFor>Phone Number<span>*</span></label>
                                                    <Input
                                                        type="text"
                                                        className="form-input"
                                                        name="email"
                                                        value={mobile}
                                                        onChange={onChangeMobile}
                                                        placeholder="0123456789"
                                                        validations={[required]}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-actions text-center">
                                            <button className="button btn-secondry" name="button" disabled={loading}>
                                                {loading && (
                                                    <i className="fa fa-refresh fa-spin" aria-hidden="true"/>
                                                )}
                                                <span>Save Changes</span>
                                            </button>
                                        </div>
                                        {message && (
                                            <div className="form-group">
                                                <div
                                                    className={"alert alert-success"}
                                                    role="alert"
                                                >
                                                    {message}
                                                </div>
                                            </div>
                                        )}
                                        <CheckButton style={{display: "none"}} ref={checkBtn}/>
                                    </Form>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>
            </section>
        </Layout>
    );
}
export default Profile;
