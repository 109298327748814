import React, {useEffect, useState} from 'react';
import Layout from "../MyLayout";
import {Link, useHistory} from "react-router-dom";
import SideMenu from "./sideMenu";
import AuthService from "../../services/auth.service";

const Account = ({product, key}) => {
    let history = useHistory();
    const [userData, setUserData] = useState(null);
    useEffect(() => {
        let getCurrentUser = AuthService.getCurrentUser();
        setUserData(getCurrentUser);
    }, []);
    const handleLogout = () => {
        AuthService.logout();
        history.push("/login");
    }
    return (
        <Layout>
            <section className="breadcrumbs">
                <div className="container">
                    <div className="breadcrumb">
                        <ul>
                            <li><Link to="/">Home</Link></li>
                            <li>Account</li>
                        </ul>
                    </div>
                </div>
            </section>
            <section className="Account_pageMn">
                <div className="container">
                    <div className="page_heading">
                        <h1>Account</h1>
                    </div>
                    <div className="Account_page_wrapper">
                        <div className="account_filter_toggle">Account Menu</div>
                        <aside className="left-colmn">
                            <div className="Account_navigations">
                                <SideMenu isActive={'account'}/>
                            </div>
                        </aside>
                        <main className="right-colmn">
                            <div className="my-account-area__content">
                                <h2>Dashboard</h2>
                                <div className="welcome">
                                    <p>Hello, <strong>{userData?.data?.first_name + ' ' + userData?.data?.last_name}</strong> (If Not <strong>{userData?.data?.first_name} !</strong> <a className="logout"
                                                                                                                                                                                         href="javascript:void(0)"
                                                                                                                                                                                         onClick={handleLogout}>Logout</a>)
                                    </p>
                                </div>
                                <p>From your account dashboard. you can easily check &amp; view your recent orders, manage your shipping and billing addresses and edit your password and account details.</p>
                            </div>
                        </main>
                    </div>
                </div>
            </section>
        </Layout>
    );
}
export default Account;
