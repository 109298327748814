import React from 'react';
import Layout from "../MyLayout";
import {Link} from "react-router-dom";
import SideMenu from "./sideMenu";

const OrderDetail = ({product, key}) => {
    return (
        <Layout>
            <section className="breadcrumbs">
                <div className="container">
                    <div className="breadcrumb">
                        <ul>
                            <li><Link to="/">Home</Link></li>
                            <li>Order</li>
                        </ul>
                    </div>
                </div>
            </section>
            <section className="OrderDetail_pageMn">
                <div className="container">
                    <div className="page_heading">
                        <h1>Order #106994</h1>
                    </div>
                    <div className="OrderDetail_page_wrapper">
                        <div className="account_filter_toggle">OrderDetail Menu</div>
                        <aside className="left-colmn">
                            <div className="OrderDetail_navigations">
                                <SideMenu isActive={'orders'}/>
                            </div>
                        </aside>
                        <main className="right-colmn">
                            <div className="my-account-area__content">
                                <h2>Order Contents</h2>
                                <div className="order_pannels">
                                    <div className="order-pannel_row">
                                        <div className="order-pannels-col">
                                            <div className="order-pannels-col_inner">
                                                <div className="order-panel_head">Sold By</div>
                                                <div className="order-panel_body">
                                                    <dl className="definitionList">
                                                        <dt className="definitionList-key">Register Office:</dt>
                                                        <dd className="definitionList-value">3741 Flanigan Oaks Drive, Pikesville, MD, Maryland, 21208</dd>
                                                        <dt className="definitionList-key">Contact No.:</dt>
                                                        <dd className="definitionList-value">301-327-3360</dd>
                                                        <dt className="definitionList-key">Cin No.:</dt>
                                                        <dd className="definitionList-value">24AABCT3518Q1Z2</dd>
                                                        <dt className="definitionList-key">Pan No.:</dt>
                                                        <dd className="definitionList-value">24AABCT3518Q1Z2</dd>
                                                        <dt className="definitionList-key">GST No.:</dt>
                                                        <dd className="definitionList-value">24AABCT3518Q1Z2</dd>
                                                    </dl>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="order-pannels-col">
                                            <div className="order-pannels-col_inner">
                                                <div className="order-panel_head">Billing Information</div>
                                                <div className="order-panel_body">
                                                    <ul className="account-order-address">
                                                        <li>Bryan Zuniga</li>
                                                        <li>3425 Nova Ct. NW</li>
                                                        <li>Albuquerque, New Mexico 87120</li>
                                                        <li>512-289-6502</li>
                                                        <li><a href="javascript:void(0)">temporary@mail.net</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="order-pannel_row">
                                        <div className="order-pannels-col">
                                            <div className="order-pannels-col_inner">
                                                <div className="order-panel_head">Order Details</div>
                                                <div className="order-panel_body">
                                                    <dl className="definitionList">
                                                        <dt className="definitionList-key">Order status:</dt>
                                                        <dd className="definitionList-value">Shipped</dd>
                                                        <dt className="definitionList-key">Order date:</dt>
                                                        <dd className="definitionList-value">Feb. 24th, 2017</dd>
                                                        <dt className="definitionList-key">Order total:</dt>
                                                        <dd className="definitionList-value">$149.99</dd>
                                                    </dl>
                                                    <div className="printInvoice_link"><a href="javascript:void(0)" onClick="window.print();return false;"><i className="fas fa-print"/> Print Invoice</a></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="order-pannels-col">
                                            <div className="order-pannels-col_inner">
                                                <div className="order-panel_head">Payment Details</div>
                                                <div className="order-panel_body">
                                                    <dl className="definitionList">
                                                        <dt className="definitionList-key">Payment method:</dt>
                                                        <dd className="definitionList-value">Credit Card</dd>
                                                        <dt className="definitionList-key">Payment Status:</dt>
                                                        <dd className="definitionList-value">Paid</dd>
                                                    </dl>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="myaccount-table table-responsive text-center orderDetails_table">
                                    <table className="table table-bordered">
                                        <thead className="thead-light">
                                        <tr>
                                            <th>S.no</th>
                                            <th>Product</th>
                                            <th>Quantity</th>
                                            <th>Unit Price</th>
                                            <th>Price</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td data-label="S.no:">1</td>
                                            <td data-label="Product:">Max Men's Half Sleeve Solid Slim fit Polo Neck T-shirt</td>
                                            <td data-label="Quantity:">1</td>
                                            <td data-label="Unit Price:">$120.00</td>
                                            <td data-label="Order:">$120.00</td>
                                        </tr>
                                        <tr>
                                            <td data-label="S.no:">2</td>
                                            <td data-label="Product:">Fabricorn Women's T-Shirt</td>
                                            <td data-label="Quantity:">2</td>
                                            <td data-label="Unit Price:">$50.00</td>
                                            <td data-label="Price:">$100.00</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="account-orderTotal">
                                    <div className="account-orderTotal_wrap">
                                        <div className="account-orderTotal_row">
                                            <div className="account-orderTotal-key">Subtotal:</div>
                                            <div className="account-orderTotal-value">$220.00</div>
                                        </div>
                                        <div className="account-orderTotal_row">
                                            <div className="account-orderTotal-key">Shipping and Handling:</div>
                                            <div className="account-orderTotal-value">Free</div>
                                        </div>
                                        <div className="account-orderTotal_row">
                                            <div className="account-orderTotal-key">Tax:</div>
                                            <div className="account-orderTotal-value">$5.00</div>
                                        </div>
                                        <div className="account-orderTotal_row">
                                            <div className="account-orderTotal-key">Grand Total:</div>
                                            <div className="account-orderTotal-value">$220.00</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>
            </section>
        </Layout>
    );
}
export default OrderDetail;
