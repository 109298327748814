import React, {useContext} from 'react';
import {CartContext} from '../../contexts/CartContext';
import CartItem from './CartItem';

const CartProducts = () => {
    const {cartItems} = useContext(CartContext);
    return (
        cartItems.map(product => <CartItem key={product.id} product={product}/>)
    );
}
export default CartProducts;
