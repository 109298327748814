import React, {useRef, useState} from 'react';
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import Layout from "../MyLayout";
import {Link} from "react-router-dom";
import SideMenu from "./sideMenu";
import {ChangeUserPassword} from "../../services/account.service";
import AuthService from "../../services/auth.service";

const required = (value) => {
    if (!value) {
        return (
            <span style={{display: 'block'}} className="form-inlineMessage error">
                This field is required!
            </span>
        );
    }
};
const vpassword = (value) => {
    if (value.length < 6 || value.length > 40) {
        return (
            <div className="text-danger" role="alert">
                The password must be between 6 and 40 characters.
            </div>
        );
    }
};
const cPassword = (value, props, components) => {
    if (value !== components['password'][0].value) { // components['password'][0].value !== components['confirm'][0].value
        return <span className="error">Passwords are not equal.</span>
    }
};
const ChangePassword = (props) => {
    const form = useRef();
    const checkBtn = useRef();
    const [currentPassword, setCurrentPassword] = useState("");
    const [password, setPassword] = useState("");
    const [cpassword, setCPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");
    const onChangeCurrentPassword = (e) => {
        const password = e.target.value;
        setCurrentPassword(password);
    };
    const onChangePassword = (e) => {
        const password = e.target.value;
        setPassword(password);
    };
    const onChangeCPassword = (e) => {
        const cpassword = e.target.value;
        setCPassword(cpassword);
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        setMessage("");
        setLoading(true);
        form.current.validateAll();
        if (checkBtn.current.context._errors.length === 0) {
            ChangeUserPassword({currentPassword: currentPassword, password: password}).then(
                (response) => {
                    setMessage(response.message);
                    setLoading(false);
                    AuthService.logout()
                    props.history.push("/login");
                },
                (error) => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    setMessage(resMessage);
                    setLoading(false);
                }
            );
        }
    };
    return (
        <Layout>
            <section className="breadcrumbs">
                <div className="container">
                    <div className="breadcrumb">
                        <ul>
                            <li><Link to="/">Home</Link></li>
                            <li>Change Password</li>
                        </ul>
                    </div>
                </div>
            </section>
            <section className="Account_pageMn">
                <div className="container">
                    <div className="page_heading">
                        <h1>Change Password</h1>
                    </div>
                    <div className="Account_page_wrapper">
                        <div className="account_filter_toggle">Account Menu</div>
                        <aside className="left-colmn">
                            <div className="Account_navigations">
                                <SideMenu isActive={'change-password'}/>
                            </div>
                        </aside>
                        <main className="right-colmn">
                            <div className="my-account-area__content">
                                <h2>Update Your Password</h2>
                                <div className="account-details-form">
                                    <Form onSubmit={handleSubmit} ref={form}>
                                        <div className="form-col2">
                                            <div className="col2">
                                                <div className="form-field">
                                                    <label className="form-label" htmlFor>Current Password<span>*</span></label>
                                                    <Input
                                                        type="password"
                                                        className="form-input"
                                                        name="currentPassword"
                                                        value={currentPassword}
                                                        onChange={onChangeCurrentPassword}
                                                        validations={[required, vpassword]}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col2">
                                                <div className="form-field">
                                                    <label className="form-label" htmlFor>New Password<span>*</span></label>
                                                    <Input
                                                        type="password"
                                                        className="form-input"
                                                        name="password"
                                                        value={password}
                                                        onChange={onChangePassword}
                                                        validations={[required, vpassword]}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-field">
                                            <label className="form-label" htmlFor>Confirm New Password<span>*</span></label>
                                            <Input
                                                type="password"
                                                className="form-input"
                                                name="cpassword"
                                                value={cpassword}
                                                onChange={onChangeCPassword}
                                                validations={[required, cPassword]}
                                            />
                                        </div>
                                        <div className="form-actions text-center">
                                            <button className="button btn-secondry" name="button" disabled={loading}>
                                                {loading && (
                                                    <i className="fa fa-refresh fa-spin" aria-hidden="true"/>
                                                )}
                                                <span>Save Changes</span>
                                            </button>
                                        </div>
                                        {message && (
                                            <div className="form-group">
                                                <div
                                                    className={"alert alert-success"}
                                                    role="alert"
                                                >
                                                    {message}
                                                </div>
                                            </div>
                                        )}
                                        <CheckButton style={{display: "none"}} ref={checkBtn}/>
                                    </Form>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>
            </section>
        </Layout>
    );
}
export default ChangePassword;
