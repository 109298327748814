import React, {useEffect, useState} from 'react';
import Layout from "../MyLayout";
import {Link} from "react-router-dom";
import SideMenu from "./sideMenu";
import {GetUserOrders} from "../../services/account.service";

const Orders = (props) => {
    const [orders, setOrders] = useState('');
    useEffect(() => {
        GetUserOrders().then(
            (response) => {
                if (response && response.data) {
                    let responseData = response.data;
                    console.log(responseData, 'responseData')
                    setOrders(responseData)
                }
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                console.log(resMessage, 'resMessage')
            }
        );
    }, [])
    return (
        <Layout>
            <section className="breadcrumbs">
                <div className="container">
                    <div className="breadcrumb">
                        <ul>
                            <li><Link to="/">Home</Link></li>
                            <li>Order</li>
                        </ul>
                    </div>
                </div>
            </section>
            <section className="Account_pageMn">
                <div className="container">
                    <div className="page_heading">
                        <h1>My Orders</h1>
                    </div>
                    <div className="Account_page_wrapper">
                        <div className="account_filter_toggle">Account Menu</div>
                        <aside className="left-colmn">
                            <div className="Account_navigations">
                                <SideMenu isActive={'orders'}/>
                            </div>
                        </aside>
                        <main className="right-colmn">
                            <div className="my-account-area__content">
                                <h2>Orders</h2>
                                <div className="myaccount-table table-responsive text-center">
                                    <table className="table table-bordered">
                                        <thead className="thead-light">
                                        <tr>
                                            <th>Order</th>
                                            <th>Date</th>
                                            <th>Status</th>
                                            <th>Total</th>
                                            <th>Action</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {orders && orders.length && orders.map(item => {
                                            return <tr>
                                                <td data-label="Order:">{item.order_id}</td>
                                                <td data-label="Date:">{item.created_at}</td>
                                                <td data-label="Status:">{item.status}</td>
                                                <td data-label="Total:">${item.amount}</td>
                                                <td data-label="Action:"><a href="javascript:void(0)" className="check-btn sqr-btn ">View</a></td>
                                            </tr>
                                        })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>
            </section>
        </Layout>
    );
}
export default Orders;
