import React, {Component} from "react";
import Home from "./components/Home";
import Products from "./components/Products";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import ProductDetails from "./components/ProductDetails";
import Login from "./components/Login";
import Register from "./components/Register";
import Cart from "./components/Cart";
import ForgotPassword from "./components/ForgotPassword";
import Checkout from "./components/Checkout";
import Orders from "./components/Account/orders";
import Account from "./components/Account";
import Address from "./components/Account/address";
import ChangePassword from "./components/Account/changePassword";
import OrderDetail from "./components/Account/orderDetails";
import Profile from "./components/Account/profile";
import WishList from "./components/Wishlist";
import PublicRoute from "./components/PublicRoute";
import PrivateRoute from "./components/PrivateRoute";
import NotFound from "./components/NotFound";
import ResetPassword from "./components/ResetPassword";

class App extends Component {
    render() {
        return (
            <BrowserRouter>
                <Switch>
                    <PublicRoute path="/" component={Home} exact/>
                    <PublicRoute path="/login" component={Login}/>
                    <PublicRoute path="/signup" component={Register}/>
                    <PublicRoute path="/forgot-password" component={ForgotPassword}/>
                    <PublicRoute path="/reset-password" component={ResetPassword}/>
                    <PublicRoute path="/products" component={Products}/>
                    <PublicRoute path="/cart" component={Cart}/>
                    <PrivateRoute path="/checkout" component={Checkout}/>
                    <PrivateRoute path="/orders" component={Orders}/>
                    <PublicRoute path="/account" component={Account}/>
                    <PublicRoute path="/address" component={Address}/>
                    <PrivateRoute path="/profile" component={Profile}/>
                    <PrivateRoute path="/wishlist" component={WishList}/>
                    <PrivateRoute path="/change-password" component={ChangePassword}/>
                    <PublicRoute path="/product/:id" component={ProductDetails}/>
                    <PrivateRoute path="/order/:id" component={OrderDetail}/>
                    <PublicRoute path="*" component={NotFound}/>
                </Switch>
            </BrowserRouter>
        );
    }
}
export default App;
