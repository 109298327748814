import React from 'react';

const CheckoutItem = ({product, key}) => {
    return (
        <li>
            <div className="product">
                <figure className="orderSummary-figure">
                    <span><img src={product.default_image} alt="prodthumb" title="prodthumb" className="cart-item-image"/></span>
                </figure>
                <div className="orderSummary-productbody">
                    <h5>Max Men's Half Sleeve Solid Slim fit Polo Neck T-shirt</h5>
                    <div className="orderSummary-qty">Quantity: {product.quantity}</div>
                    <div className="orderSummary-price">Price: {product.price}</div>
                </div>
            </div>
        </li>
    );
}
export default CheckoutItem;
