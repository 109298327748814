import React, {useEffect, useState, useContext} from 'react'
import {Link, useHistory} from "react-router-dom";
import AuthService from "../../services/auth.service";
import {CartContext} from "../../contexts/CartContext";

function Header(props) {
    const {itemCount} = useContext(CartContext);
    let history = useHistory();
    const [userData, setUserData] = useState(null);
    const [searchData, setSearchData] = useState(null);
    useEffect(() => {
        let getCurrentUser = AuthService.getCurrentUser();
        setUserData(getCurrentUser);
    }, []);
    const handleLogout = () => {
        AuthService.logout();
        history.push("/login");
    }
    const handleOpen = () => {
        window.$(".search-overlay").addClass("active");
    }
    const handleClose = () => {
        window.$(".search-overlay").removeClass("active");
    }
    const handleSearch = (event) => {
        console.log(event.target.value)
        setSearchData(event.target.value)
        // history.push("/products?search=" + event.target.value);
    }
    const handleSearchSubmit = (event) => {
        event.preventDefault();
        window.location.href = "/products?search=" + searchData;
    }
    return (
        <>
            <header className="header">
                <div className="htop-bar">
                    <div className="container-lg">
                        <div className="htop-bar-inner">
                            <div className="left-colmn">
                                <div className="hcontact-info">
                                    <ul>
                                        <li className="wow fadeInDown" data-wow-delay="0.1s"><a
                                            href="tel:+2123456789"><i className="fas fa-phone-alt"/>+21 23456789</a></li>
                                        <li className="wow fadeInDown" data-wow-delay="0.2s"><a
                                            href="mailto:store@domain.com"><i className="far fa-envelope"/>store@domain.com</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="right-colmn">
                                <div className="htop-sale-message wow fadeInDown" data-wow-delay="0.2s">Summer sale
                                    discount
                                    off <span>50%</span>! Shop Now
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="hmiddle">
                    <div className="container-lg">
                        <div className="hmiddle-inner">
                            <div className="left-colmn">
                                <div className="hsocial-icon">
                                    <ul>
                                        <li className="wow fadeInUp" data-wow-delay="0.1s"><a href="#"
                                                                                              target="_blank"><i className="fab fa-facebook-f"/></a></li>
                                        <li className="wow fadeInUp" data-wow-delay="0.2s"><a href="#"
                                                                                              target="_blank"><i className="fab fa-twitter"/></a></li>
                                        <li className="wow fadeInUp" data-wow-delay="0.3s"><a href="#"
                                                                                              target="_blank"><i className="fab fa-instagram"/></a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="middle-colmn">
                                {/* <div className="logo"><a href="/">
                                    <img src="/images/store-logo.png"
                                         alt="store.com"
                                         title="store.com"/> </a></div> */}
                                <div className="logo"><Link to="/"><img src="/images/store-logo.png"
                                                                        alt="store.com"
                                                                        title="store.com"/></Link>{' '}</div>
                            </div>
                            <div className="right-colmn">
                                <div className="hcontent_icons">
                                    <ul>
                                        <li className="wow fadeInUp" data-wow-delay="0.1s" onClick={handleOpen}><a id="hsearch" href="javascript:;"><i className="fas fa-search"/></a></li>
                                        <li className="h-account wow fadeInUp" data-wow-delay="0.2s"><a href="#"><i className="far fa-user-circle"/></a>
                                            <div className="h-account_flyout">
                                                <ul>
                                                    {userData ?
                                                        <>
                                                            <li><Link to="/account">My Account</Link></li>
                                                            <li><Link to="/profile">View Profile</Link></li>
                                                            <li><Link to="/orders">My Order</Link></li>
                                                            <li><Link to="/address">My Order Address</Link></li>
                                                            <li><Link to="/change-password">Change Password</Link></li>
                                                            <li><a href="javascript:void(0)" onClick={handleLogout}>Logout</a></li>
                                                        </> :
                                                        <>
                                                            <li><Link to="/login">Sign in</Link></li>
                                                            <li><Link to="/signup">Register</Link></li>
                                                        </>}
                                                </ul>
                                            </div>
                                        </li>
                                        <li className="wow fadeInUp" data-wow-delay="0.3s"><Link to="/wishlist"><i className="far fa-heart"/></Link></li>
                                        <li className="hcartmn wow fadeInUp" data-wow-delay="0.4s">
                                            <Link to="/cart">
                                                <div className="hcart-counter">{itemCount}</div>
                                                <i className="fas fa-shopping-cart"/>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="hamburger-menu wow fadeInUp" data-wow-delay="0.5s"><a href="#my-menu"><i className="fas fa-bars"/> <i className="fas fa-times"></i></a></div>
                            </div>
                        </div>
                    </div>
                    <div className="search-overlay">
                        <button className="search-overlay_close-icon" onClick={handleClose}><i className="fas fa-times"/></button>
                        <div className="search-overlay_content">
                            <form className="space-mb--20" onSubmit={(e) => handleSearchSubmit(e)}>
                                <input type="search" placeholder="Search Products..." onChange={(e) => handleSearch(e)}/>
                            </form>
                            <div className="search-overlay_hint"># Hit enter to search</div>
                        </div>
                    </div>
                </div>
                <div className="head-bottom">
                    <div className="container">
                        <div className="head-bottom-inner">
                            <nav className="desktop-navigation">
                                <ul>
                                    <li>
                                        <Link to="/">Home</Link>{' '}
                                    </li>
                                    <li>
                                        <a href="#">Shop</a>
                                        <ul className="sub-menu sub-menu--mega sub-menu--mega--column-5">
                                            <li className="sub-menu--mega__title">
                                                <h2><a href="/">Home Group</a></h2>
                                                <ul className="sub-menu--mega__list">
                                                    <li><a href="#">Sample Category</a></li>
                                                    <li><a href="#">Sample Category</a></li>
                                                    <li><a href="#">Sample Category</a></li>
                                                    <li><a href="#">Sample Category</a></li>
                                                </ul>
                                            </li>
                                            <li className="sub-menu--mega__title">
                                                <h2><a href="/">Home Group</a></h2>
                                                <ul className="sub-menu--mega__list">
                                                    <li><a href="#">Sample Category</a></li>
                                                    <li><a href="#">Sample Category</a></li>
                                                    <li><a href="#">Sample Category</a></li>
                                                    <li><a href="#">Sample Category</a></li>
                                                </ul>
                                            </li>
                                            <li className="sub-menu--mega__title">
                                                <h2><a href="/">Home Group</a></h2>
                                                <ul className="sub-menu--mega__list">
                                                    <li><a href="#">Sample Category</a></li>
                                                    <li><a href="#">Sample Category</a></li>
                                                    <li><a href="#">Sample Category</a></li>
                                                    <li><a href="#">Sample Category</a></li>
                                                </ul>
                                            </li>
                                            <li className="sub-menu--mega__title">
                                                <h2><a href="/">Home Group</a></h2>
                                                <ul className="sub-menu--mega__list">
                                                    <li><a href="#">Sample Category</a></li>
                                                    <li><a href="#">Sample Category</a></li>
                                                    <li><a href="#">Sample Category</a></li>
                                                    <li><a href="#">Sample Category</a></li>
                                                </ul>
                                            </li>
                                            <li className="sub-menu--mega__title">
                                                <h2><a href="/">Home Group</a></h2>
                                                <ul className="sub-menu--mega__list">
                                                    <li><a href="#">Sample Category</a></li>
                                                    <li><a href="#">Sample Category</a></li>
                                                    <li><a href="#">Sample Category</a></li>
                                                    <li><a href="#">Sample Category</a></li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <Link to="/products">Products</Link>{' '}
                                    </li>
                                    <li><a href="#">Pages</a></li>
                                    <li><a href="#">Elements</a></li>
                                    <li><a href="#">Accessories</a></li>
                                    <li><a href="#">Sale</a></li>
                                </ul>
                            </nav>
                            <nav id="my-menu" style={{display: 'none'}}>
                                <ul>
                                    <li><Link href="/">Home</Link></li>
                                    <li><a href="javascript:void(0)">Shop</a>
                                        <ul>
                                            <li><a href="#">Home Group 01</a>
                                                <ul>
                                                    <li><a href="#">Sample Category</a></li>
                                                    <li><a href="#">Sample Category</a></li>
                                                    <li><a href="#">Sample Category</a></li>
                                                    <li><a href="#">Sample Category</a></li>
                                                </ul>
                                            </li>
                                            <li><a href="#">Home Group 02</a>
                                                <ul>
                                                    <li><a href="#">Sample Category</a></li>
                                                    <li><a href="#">Sample Category</a></li>
                                                    <li><a href="#">Sample Category</a></li>
                                                    <li><a href="#">Sample Category</a></li>
                                                </ul>
                                            </li>
                                            <li><a href="#">Home Group 03</a>
                                                <ul>
                                                    <li><a href="#">Sample Category</a></li>
                                                    <li><a href="#">Sample Category</a></li>
                                                    <li><a href="#">Sample Category</a></li>
                                                    <li><a href="#">Sample Category</a></li>
                                                </ul>
                                            </li>
                                            <li><a href="#">Home Group 04</a>
                                                <ul>
                                                    <li><a href="#">Sample Category</a></li>
                                                    <li><a href="#">Sample Category</a></li>
                                                    <li><a href="#">Sample Category</a></li>
                                                    <li><a href="#">Sample Category</a></li>
                                                </ul>
                                            </li>
                                            <li><a href="#">Home Group 05</a>
                                                <ul>
                                                    <li><a href="#">Sample Category</a></li>
                                                    <li><a href="#">Sample Category</a></li>
                                                    <li><a href="#">Sample Category</a></li>
                                                    <li><a href="#">Sample Category</a></li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </li>
                                    <li><a href="#">Product</a></li>
                                    <li><a href="#">Pages</a></li>
                                    <li><a href="#">Elements</a></li>
                                    <li><a href="#">Accessories</a></li>
                                    <li><a href="#">Sale</a></li>
                                    <li><Link to="/login">Login</Link></li>
                                    <li><Link to="/signup">Register</Link></li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
}
export default Header;
