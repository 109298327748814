import axios from "axios";

// const API_URL = "http://localhost:8082/user/";
import Layout, { siteURL } from "../components/MyLayout";
const register = (first_name, last_name, email, password) => {
    return axios.post(`${siteURL}` + "user/register", {
        first_name,
        last_name,
        email,
        phone_number: '',
        password,
    });
};
const login = (email, password) => {
    return axios
        .post(`${siteURL}` + "user/login", {
            email,
            password,
        })
        .then((response) => {
            if (response.data.token) {
                localStorage.setItem("user", JSON.stringify(response.data));
            }
            return response.data;
        });
};
const forgotPassword = (email) => {
    return axios
        .post(`${siteURL}` + "user/forgot-password", {
            email
        })
        .then((response) => {
            return response.data;
        }).catch(err => err);
};
const resetPassword = (email, password) => {
    return axios
        .post(`${siteURL}` + "user/reset-password", {
            email, password
        })
        .then((response) => {
            return response.data;
        }).catch(err => err);
};
const logout = () => {
    localStorage.removeItem("user");
};
const getCurrentUser = () => {
    return JSON.parse(localStorage.getItem("user"));
};

export default {
    register,
    login,
    forgotPassword,
    resetPassword,
    logout,
    getCurrentUser,
};
