import React from 'react';
import {Link, useHistory} from "react-router-dom";
import AuthService from "../../services/auth.service";

const SideMenu = (props) => {
    let history = useHistory();
    const {isActive} = props;
    const handleLogout = () => {
        AuthService.logout();
        history.push("/login");
    }
    return (
        <ul>
            <li className={isActive === 'account' ? 'active' : ''}><Link to="/account" activeClassName='active'>Dashboard</Link></li>
            <li className={isActive === 'profile' ? 'active' : ''}><Link to="/profile">View Profile</Link></li>
            <li className={isActive === 'orders' ? 'active' : ''}><Link to="/orders">My Order</Link></li>
            <li className={isActive === 'address' ? 'active' : ''}><Link to="/address">My Order Address</Link></li>
            <li className={isActive === 'change-password' ? 'active' : ''}><Link to="/change-password">Change Password</Link></li>
            <li><a href="javascript:void(0)" onClick={handleLogout}>Logout</a></li>
        </ul>
    );
}
export default SideMenu;

