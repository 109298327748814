import React, {useState, useRef} from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import {isEmail} from "validator";
import {Link} from "react-router-dom";
import Layout from "../MyLayout";
import AuthService from "../../services/auth.service";

const required = (value) => {
    if (!value) {
        return (
            <span style={{display: 'block'}} className="form-inlineMessage error">
                This field is required!
            </span>
        );
    }
};
const email = value => {
    if (!isEmail(value)) {
        return (
            <span style={{display: 'block'}} className="form-inlineMessage error">Please use a valid email address, such as user@example.com.</span>
        );
    }
};
const Login = (props) => {
    const form = useRef();
    const checkBtn = useRef();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");
    const onChangeUsername = (e) => {
        const username = e.target.value;
        setUsername(username);
    };
    const onChangePassword = (e) => {
        const password = e.target.value;
        setPassword(password);
    };
    const handleLogin = (e) => {
        e.preventDefault();
        setMessage("");
        setLoading(true);
        form.current.validateAll();
        if (checkBtn.current.context._errors.length === 0) {
            AuthService.login(username, password).then(
                (response) => {
                    if (response.status === 200) {
                        props.history.push("/");
                        window.location.reload();
                    } else {
                        setLoading(false);
                        setMessage(response.message);
                    }
                },
                (error) => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    setLoading(false);
                    setMessage(resMessage);
                }
            );
        } else {
            setLoading(false);
        }
    };
    return (
        <Layout>
            <section className="breadcrumbs">
                <div className="container">
                    <div className="breadcrumb">
                        <ul>
                            <li><Link to="/">Home</Link></li>
                            <li>Sign In</li>
                        </ul>
                    </div>
                </div>
            </section>
            <section className="register_page_Mn">
                <div className="container">
                    <div className="page_heading text-center">
                        <h1>Sign In</h1>
                    </div>
                    <div className="register_page_wrapper">
                        <Form onSubmit={handleLogin} ref={form}>
                            <div className="login_form form_Mn">
                                <div className="form-field">
                                    <label className="form-label" htmlFor="login_email">Email Address<span
                                        className="required">*</span></label>
                                    <Input
                                        type="text"
                                        className="form-control input_user"
                                        name="username"
                                        value={username}
                                        onChange={onChangeUsername}
                                        validations={[required, email]}
                                    />
                                </div>
                                <div className="form-field">
                                    <label className="form-label" htmlFor="login_pass">Password<span
                                        className="required">*</span></label>
                                    <Input
                                        type="password"
                                        className="form-control input_pass"
                                        name="password"
                                        value={password}
                                        onChange={onChangePassword}
                                        validations={[required]}
                                    />
                                </div>
                                <div className="form-actions text-center">
                                    <button className="button btn-secondry" name="button" disabled={loading}>
                                        {loading && (
                                            <i className="fa fa-spinner fa-spin" aria-hidden="true"/>
                                        )}
                                        <span>Login</span>
                                    </button>
                                    <Link className="forgot-password" to="/forgot-password">Forgot password?</Link>
                                    {message && (
                                        <div className="form-group">
                                            <span style={{display: 'block'}} className="form-inlineMessage error">{message}</span>
                                        </div>
                                    )}
                                </div>
                                <CheckButton style={{display: "none"}} ref={checkBtn}/>
                                <div className="form-actions-new-customer text-center">
                                    <p className="register-link">Don't have an account? <Link to="/signup">Register
                                        here</Link></p>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </section>
        </Layout>
    );
}
export default Login;
