import React, {useContext} from 'react';
import {Link} from "react-router-dom";
import {CartContext} from "../../contexts/CartContext";

function WishListItems({removeWishList, items}) {
    const {addProduct, cartItems, increase} = useContext(CartContext);
    const isInCart = product => {
        return !!cartItems.find(item => item.id === product.id);
    }
    return (
        <>
            <li>
                <article className="product-grid_artcile">
                    <figure className="product-grid-figure">
                        <a className="product-grid-image-wrap" href="javascript:void(0)"><img src={items.default_image} alt="product" title={items.title}/></a>
                        <figcaption className="product-grid-figcaption">
                            <div className="product-grid_floating-badges">
                                {items.is_sell === 1 ? <span className="sale badge">Sale</span> : <span className="new badge">New</span>}
                            </div>
                        </figcaption>
                    </figure>
                    <div className="product-grid_content">
                        <div className="title">
                            <h3><Link to={"/product/" + items.id}>{items.title}</Link></h3>
                        </div>
                        <div className="product-grid_rating">
                            <div className="prodgrid_star">
                                <ul>
                                    <li><a href="javascript:void(0)"><i className="fas fa-star"/></a></li>
                                    <li><a href="javascript:void(0)"><i className="fas fa-star"/></a></li>
                                    <li><a href="javascript:void(0)"><i className="fas fa-star"/></a></li>
                                    <li><a href="javascript:void(0)"><i className="fas fa-star"/></a></li>
                                    <li><a href="javascript:void(0)"><i className="far fa-star"/></a></li>
                                </ul>
                            </div>
                            <div className="prodgrid-rating_count">(45)</div>
                        </div>
                        <div className="product-grid_price">
                            {items.discount > 0 ?
                                <>
                                    <span className="discounted-price">{items.discounted_price}</span>
                                    <span className="main-price discounted">{items.price}</span>
                                    <span className="yousave-price">{items.discount}% Off</span>
                                </>
                                : <span className="discounted-price">{items.price}</span>}
                        </div>
                    </div>
                    <div className="wishlist_action">
                        {
                            isInCart(items) &&
                            <a href="javascript:void(0)" className="btn-primary wow fadeInUp" onClick={() => increase(items)}>Add more</a>
                        }
                        {
                            !isInCart(items) &&
                            <a href="javascript:void(0)" className="btn-primary wow fadeInUp" onClick={() => addProduct(items)}>Add to Cart</a>
                        }
                        <a className="btn-secondry wow fadeInUp" href="javascript:void(0)" onClick={() => removeWishList(items)}>Remove</a></div>
                </article>
            </li>
        </>
    );
}
export default WishListItems;
