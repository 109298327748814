import React, {useEffect, useRef, useState} from 'react';
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import {isEmail} from "validator";
import Layout from "../MyLayout";
import {Link} from "react-router-dom";
import SideMenu from "./sideMenu";
import {SaveUserAddress, GetUserAddress} from "../../services/account.service";
import Select from "react-validation/build/select";

const required = (value) => {
    if (!value) {
        return (
            <span style={{display: 'block'}} className="form-inlineMessage error">
                This field is required!
            </span>
        );
    }
};
const validEmail = (value) => {
    if (!isEmail(value)) {
        return (
            <span style={{display: 'block'}} className="form-inlineMessage error">Please use a valid email address, such as user@example.com.</span>
        );
    }
};
const Address = (props) => {
    const form = useRef();
    const checkBtn = useRef();
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [mobile, setMobile] = useState("");
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");
    const [bAddressLine1, setBAddressLine1] = useState('');
    const [bAddressLine2, setBAddressLine2] = useState('');
    const [bCity, setBCity] = useState('');
    const [bState, setBState] = useState('');
    const [bCountry, setBCountry] = useState('');
    const [bZipCode, setBZipCode] = useState('');
    const [addressId, setUserAddressId] = useState('');
    useEffect(() => {
        GetUserAddress().then(
            (response) => {
                if (response && response.data) {
                    let responseData = response.data;
                    setUserAddressId(responseData.id)
                    setFirstName(responseData.first_name)
                    setLastName(responseData.last_name)
                    setEmail(responseData.email)
                    setMobile(responseData.mobile)
                    setBAddressLine1(responseData.address_1)
                    setBAddressLine2(responseData.address_2)
                    setBCountry(responseData.country)
                    setBState(responseData.state)
                    setBCity(responseData.city)
                    setBZipCode(responseData.zip_code)
                }
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                setMessage(resMessage);
            }
        );
    }, [])
    const onChangeFirstName = (e) => {
        const firstname = e.target.value;
        setFirstName(firstname);
    };
    const onChangeLastName = (e) => {
        const lastname = e.target.value;
        setLastName(lastname);
    };
    const onChangeEmail = (e) => {
        const email = e.target.value;
        setEmail(email);
    };
    const onChangeMobile = (e) => {
        const mobile = e.target.value;
        setMobile(mobile);
    };
    const onChangeBAddressLine1 = (e) => {
        const BAddressLine1 = e.target.value;
        setBAddressLine1(BAddressLine1);
    };
    const onChangeBAddressLine2 = (e) => {
        const BAddressLine2 = e.target.value;
        setBAddressLine2(BAddressLine2);
    };
    const onChangeBCity = (e) => {
        const BCity = e.target.value;
        setBCity(BCity);
    };
    const onChangeBState = (e) => {
        const BState = e.target.value;
        setBState(BState);
    };
    const onChangeBCountry = (e) => {
        const BCountry = e.target.value;
        setBCountry(BCountry);
    };
    const onChangeBZipCode = (e) => {
        const BZipCode = e.target.value;
        setBZipCode(BZipCode);
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        setMessage("");
        setLoading(true);
        form.current.validateAll();
        if (checkBtn.current.context._errors.length === 0) {
            SaveUserAddress({
                id: addressId,
                first_name: firstName,
                last_name: lastName,
                email: email,
                mobile: mobile,
                address_1: bAddressLine1,
                address_2: bAddressLine2,
                country: bCountry,
                state: bState,
                city: bCity,
                zip_code: bZipCode,
            }).then(
                (response) => {
                    setMessage(response.message);
                    setLoading(false);
                },
                (error) => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    setMessage(resMessage);
                    setLoading(false);
                }
            );
        }
    };
    return (
        <Layout>
            <section className="breadcrumbs">
                <div className="container">
                    <div className="breadcrumb">
                        <ul>
                            {/* <li><a href="index.html">Home</a></li> */}
                            <li><Link to="/">Home</Link></li>
                            <li>My Order Address</li>
                        </ul>
                    </div>
                </div>
            </section>
            <section className="Account_pageMn">
                <div className="container">
                    <div className="page_heading">
                        <h1>My Order Address</h1>
                    </div>
                    <div className="Account_page_wrapper">
                        <div className="account_filter_toggle">Account Menu</div>
                        <aside className="left-colmn">
                            <div className="Account_navigations">
                                <SideMenu isActive={'address'}/>
                            </div>
                        </aside>
                        <main className="right-colmn">
                            <div className="my-account-area__content">
                                <h2>Add Address</h2>
                                <Form onSubmit={handleSubmit} ref={form}>
                                    <div className="account-details-form">
                                        <div className="form-col2">
                                            <div className="col2">
                                                <div className="form-field">
                                                    <label className="form-label" htmlFor>First Name<span>*</span></label>
                                                    <Input
                                                        type="text"
                                                        className="form-input"
                                                        name="first_name"
                                                        placeholder="Enter First Name"
                                                        value={firstName}
                                                        onChange={onChangeFirstName}
                                                        validations={[required]}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col2">
                                                <div className="form-field">
                                                    <label className="form-label" htmlFor>Last Name<span>*</span></label>
                                                    <Input
                                                        type="text"
                                                        className="form-input"
                                                        name="last_name"
                                                        placeholder="Enter Last Name"
                                                        value={lastName}
                                                        onChange={onChangeLastName}
                                                        validations={[required]}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-col2">
                                            <div className="col2">
                                                <div className="form-field">
                                                    <label className="form-label" htmlFor>Email Address<span>*</span></label>
                                                    <Input
                                                        type="text"
                                                        className="form-input"
                                                        name="email"
                                                        value={email}
                                                        onChange={onChangeEmail}
                                                        placeholder="Email"
                                                        validations={[required, validEmail]}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col2">
                                                <div className="form-field">
                                                    <label className="form-label" htmlFor>Phone Number<span>*</span></label>
                                                    <Input
                                                        type="text"
                                                        className="form-input"
                                                        name="email"
                                                        value={mobile}
                                                        onChange={onChangeMobile}
                                                        placeholder="0123456789"
                                                        validations={[required]}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-col2">
                                            <div className="col2">
                                                <div className="form-field">
                                                    <label className="form-label" htmlFor>Address Line 1<span>*</span></label>
                                                    <Input
                                                        type="text"
                                                        id="adr"
                                                        name="addressLine1"
                                                        className="form-input"
                                                        placeholder="Address Line 1"
                                                        value={bAddressLine1}
                                                        onChange={onChangeBAddressLine1}
                                                        validations={[required]}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col2">
                                                <div className="form-field">
                                                    <label className="form-label" htmlFor>Address Line 2<span>*</span></label>
                                                    <Input
                                                        type="text"
                                                        id="adr"
                                                        className="form-input"
                                                        name="addressLine2"
                                                        placeholder="Address Line 2"
                                                        value={bAddressLine2}
                                                        onChange={onChangeBAddressLine2}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-col2">
                                            <div className="col2">
                                                <div className="form-field">
                                                    <label className="form-label" htmlFor>Country<span>*</span></label>
                                                    <Select name='country' className="form-select" value={bCountry} onChange={onChangeBCountry} validations={[required]}>
                                                        <option selected value>Choose a Country</option>
                                                        <option value="Afghanistan">Afghanistan</option>
                                                        <option value="Albania">Albania</option>
                                                        <option value="Algeria">Algeria</option>
                                                        <option value="American Samoa">American Samoa</option>
                                                        <option value="Andorra">Andorra</option>
                                                        <option value="Angola">Angola</option>
                                                        <option value="Anguilla">Anguilla</option>
                                                        <option value="Antarctica">Antarctica</option>
                                                        <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                                                        <option value="Argentina">Argentina</option>
                                                        <option value="Armenia">Armenia</option>
                                                        <option value="Aruba">Aruba</option>
                                                        <option value="Australia">Australia</option>
                                                        <option value="Austria">Austria</option>
                                                        <option value="Azerbaijan">Azerbaijan</option>
                                                        <option value="Bahamas">Bahamas</option>
                                                        <option value="Bahrain">Bahrain</option>
                                                        <option value="Bangladesh">Bangladesh</option>
                                                        <option value="Barbados">Barbados</option>
                                                        <option value="Belarus">Belarus</option>
                                                        <option value="Belgium">Belgium</option>
                                                        <option value="Belize">Belize</option>
                                                        <option value="Benin">Benin</option>
                                                        <option value="Bermuda">Bermuda</option>
                                                        <option value="Bhutan">Bhutan</option>
                                                        <option value="Bolivia">Bolivia</option>
                                                        <option value="Bonaire, Sint Eustatius and Saba">Bonaire, Sint Eustatius and Saba</option>
                                                        <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                                                        <option value="Botswana">Botswana</option>
                                                        <option value="Bouvet Island">Bouvet Island</option>
                                                        <option value="Brazil">Brazil</option>
                                                        <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
                                                        <option value="Brunei Darussalam">Brunei Darussalam</option>
                                                        <option value="Bulgaria">Bulgaria</option>
                                                        <option value="Burkina Faso">Burkina Faso</option>
                                                        <option value="Burundi">Burundi</option>
                                                        <option value="Cambodia">Cambodia</option>
                                                        <option value="Cameroon">Cameroon</option>
                                                        <option value="Canada">Canada</option>
                                                        <option value="Cape Verde">Cape Verde</option>
                                                        <option value="Cayman Islands">Cayman Islands</option>
                                                        <option value="Central African Republic">Central African Republic</option>
                                                        <option value="Chad">Chad</option>
                                                        <option value="Chile">Chile</option>
                                                        <option value="China">China</option>
                                                        <option value="Christmas Island">Christmas Island</option>
                                                        <option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
                                                        <option value="Colombia">Colombia</option>
                                                        <option value="Comoros">Comoros</option>
                                                        <option value="Congo">Congo</option>
                                                        <option value="Congo, the Democratic Republic of the">Congo, the Democratic Republic of the</option>
                                                        <option value="Cook Islands">Cook Islands</option>
                                                        <option value="Costa Rica">Costa Rica</option>
                                                        <option value="Cote d'Ivoire">Cote d'Ivoire</option>
                                                        <option value="Croatia">Croatia</option>
                                                        <option value="Cyprus">Cyprus</option>
                                                        <option value="Czech Republic">Czech Republic</option>
                                                        <option value="Denmark">Denmark</option>
                                                        <option value="Djibouti">Djibouti</option>
                                                        <option value="Dominica">Dominica</option>
                                                        <option value="Dominican Republic">Dominican Republic</option>
                                                        <option value="Ecuador">Ecuador</option>
                                                        <option value="Egypt">Egypt</option>
                                                        <option value="El Salvador">El Salvador</option>
                                                        <option value="Equatorial Guinea">Equatorial Guinea</option>
                                                        <option value="Eritrea">Eritrea</option>
                                                        <option value="Estonia">Estonia</option>
                                                        <option value="Ethiopia">Ethiopia</option>
                                                        <option value="Falkland Islands (Malvinas)">Falkland Islands (Malvinas)</option>
                                                        <option value="Faroe Islands">Faroe Islands</option>
                                                        <option value="Fiji">Fiji</option>
                                                        <option value="Finland">Finland</option>
                                                        <option value="France">France</option>
                                                        <option value="French Guiana">French Guiana</option>
                                                        <option value="French Polynesia">French Polynesia</option>
                                                        <option value="French Southern Territories">French Southern Territories</option>
                                                        <option value="Gabon">Gabon</option>
                                                        <option value="Gambia">Gambia</option>
                                                        <option value="Georgia">Georgia</option>
                                                        <option value="Germany">Germany</option>
                                                        <option value="Ghana">Ghana</option>
                                                        <option value="Gibraltar">Gibraltar</option>
                                                        <option value="Greece">Greece</option>
                                                        <option value="Greenland">Greenland</option>
                                                        <option value="Grenada">Grenada</option>
                                                        <option value="Guadeloupe">Guadeloupe</option>
                                                        <option value="Guam">Guam</option>
                                                        <option value="Guatemala">Guatemala</option>
                                                        <option value="Guernsey">Guernsey</option>
                                                        <option value="Guinea">Guinea</option>
                                                        <option value="Guinea-Bissau">Guinea-Bissau</option>
                                                        <option value="Guyana">Guyana</option>
                                                        <option value="Haiti">Haiti</option>
                                                        <option value="Heard Island and Mcdonald Islands">Heard Island and Mcdonald Islands</option>
                                                        <option value="Holy See (Vatican City State)">Holy See (Vatican City State)</option>
                                                        <option value="Honduras">Honduras</option>
                                                        <option value="Hong Kong">Hong Kong</option>
                                                        <option value="Hungary">Hungary</option>
                                                        <option value="Iceland">Iceland</option>
                                                        <option value="India">India</option>
                                                        <option value="Indonesia">Indonesia</option>
                                                        <option value="Iraq">Iraq</option>
                                                        <option value="Ireland">Ireland</option>
                                                        <option value="Isle of Man">Isle of Man</option>
                                                        <option value="Israel">Israel</option>
                                                        <option value="Italy">Italy</option>
                                                        <option value="Jamaica">Jamaica</option>
                                                        <option value="Japan">Japan</option>
                                                        <option value="Jersey">Jersey</option>
                                                        <option value="Jordan">Jordan</option>
                                                        <option value="Kazakhstan">Kazakhstan</option>
                                                        <option value="Kenya">Kenya</option>
                                                        <option value="Kiribati">Kiribati</option>
                                                        <option value="Korea, Republic of">Korea, Republic of</option>
                                                        <option value="Kuwait">Kuwait</option>
                                                        <option value="Kyrgyzstan">Kyrgyzstan</option>
                                                        <option value="Lao People's Democratic Republic">Lao People's Democratic Republic</option>
                                                        <option value="Latvia">Latvia</option>
                                                        <option value="Lebanon">Lebanon</option>
                                                        <option value="Lesotho">Lesotho</option>
                                                        <option value="Liberia">Liberia</option>
                                                        <option value="Libya">Libya</option>
                                                        <option value="Liechtenstein">Liechtenstein</option>
                                                        <option value="Lithuania">Lithuania</option>
                                                        <option value="Luxembourg">Luxembourg</option>
                                                        <option value="Macao">Macao</option>
                                                        <option value="Macedonia, the Former Yugoslav Republic of">Macedonia, the Former Yugoslav Republic of</option>
                                                        <option value="Madagascar">Madagascar</option>
                                                        <option value="Malawi">Malawi</option>
                                                        <option value="Malaysia">Malaysia</option>
                                                        <option value="Maldives">Maldives</option>
                                                        <option value="Mali">Mali</option>
                                                        <option value="Malta">Malta</option>
                                                        <option value="Marshall Islands">Marshall Islands</option>
                                                        <option value="Martinique">Martinique</option>
                                                        <option value="Mauritania">Mauritania</option>
                                                        <option value="Mauritius">Mauritius</option>
                                                        <option value="Mayotte">Mayotte</option>
                                                        <option value="Mexico">Mexico</option>
                                                        <option value="Micronesia, Federated States of">Micronesia, Federated States of</option>
                                                        <option value="Moldova, Republic of">Moldova, Republic of</option>
                                                        <option value="Monaco">Monaco</option>
                                                        <option value="Mongolia">Mongolia</option>
                                                        <option value="Montenegro">Montenegro</option>
                                                        <option value="Montserrat">Montserrat</option>
                                                        <option value="Morocco">Morocco</option>
                                                        <option value="Mozambique">Mozambique</option>
                                                        <option value="Myanmar">Myanmar</option>
                                                        <option value="Namibia">Namibia</option>
                                                        <option value="Nauru">Nauru</option>
                                                        <option value="Nepal">Nepal</option>
                                                        <option value="Netherlands">Netherlands</option>
                                                        <option value="Netherlands Antilles">Netherlands Antilles</option>
                                                        <option value="New Caledonia">New Caledonia</option>
                                                        <option value="New Zealand">New Zealand</option>
                                                        <option value="Nicaragua">Nicaragua</option>
                                                        <option value="Niger">Niger</option>
                                                        <option value="Nigeria">Nigeria</option>
                                                        <option value="Niue">Niue</option>
                                                        <option value="Norfolk Island">Norfolk Island</option>
                                                        <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                                                        <option value="Norway">Norway</option>
                                                        <option value="Oman">Oman</option>
                                                        <option value="Pakistan">Pakistan</option>
                                                        <option value="Palau">Palau</option>
                                                        <option value="Palestinian Territory, Occupied">Palestinian Territory, Occupied</option>
                                                        <option value="Panama">Panama</option>
                                                        <option value="Papua New Guinea">Papua New Guinea</option>
                                                        <option value="Paraguay">Paraguay</option>
                                                        <option value="Peru">Peru</option>
                                                        <option value="Philippines">Philippines</option>
                                                        <option value="Pitcairn">Pitcairn</option>
                                                        <option value="Poland">Poland</option>
                                                        <option value="Portugal">Portugal</option>
                                                        <option value="Puerto Rico">Puerto Rico</option>
                                                        <option value="Qatar">Qatar</option>
                                                        <option value="Republic of Kosovo">Republic of Kosovo</option>
                                                        <option value="Reunion">Reunion</option>
                                                        <option value="Romania">Romania</option>
                                                        <option value="Russian Federation">Russian Federation</option>
                                                        <option value="Rwanda">Rwanda</option>
                                                        <option value="Saint Helena">Saint Helena</option>
                                                        <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                                                        <option value="Saint Lucia">Saint Lucia</option>
                                                        <option value="Saint Pierre and Miquelon">Saint Pierre and Miquelon</option>
                                                        <option value="Saint Vincent and the Grenadines">Saint Vincent and the Grenadines</option>
                                                        <option value="Samoa">Samoa</option>
                                                        <option value="San Marino">San Marino</option>
                                                        <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                                                        <option value="Saudi Arabia">Saudi Arabia</option>
                                                        <option value="Senegal">Senegal</option>
                                                        <option value="Serbia">Serbia</option>
                                                        <option value="Seychelles">Seychelles</option>
                                                        <option value="Sierra Leone">Sierra Leone</option>
                                                        <option value="Singapore">Singapore</option>
                                                        <option value="Slovakia">Slovakia</option>
                                                        <option value="Slovenia">Slovenia</option>
                                                        <option value="Solomon Islands">Solomon Islands</option>
                                                        <option value="Somalia">Somalia</option>
                                                        <option value="South Africa">South Africa</option>
                                                        <option value="South Georgia and the South Sandwich Islands">South Georgia and the South Sandwich Islands</option>
                                                        <option value="Spain">Spain</option>
                                                        <option value="Sri Lanka">Sri Lanka</option>
                                                        <option value="Sudan">Sudan</option>
                                                        <option value="Suriname">Suriname</option>
                                                        <option value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</option>
                                                        <option value="Swaziland">Swaziland</option>
                                                        <option value="Sweden">Sweden</option>
                                                        <option value="Switzerland">Switzerland</option>
                                                        <option value="Taiwan">Taiwan</option>
                                                        <option value="Tajikistan">Tajikistan</option>
                                                        <option value="Tanzania, United Republic of">Tanzania, United Republic of</option>
                                                        <option value="Thailand">Thailand</option>
                                                        <option value="Timor-Leste">Timor-Leste</option>
                                                        <option value="Togo">Togo</option>
                                                        <option value="Tokelau">Tokelau</option>
                                                        <option value="Tonga">Tonga</option>
                                                        <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                                                        <option value="Tunisia">Tunisia</option>
                                                        <option value="Turkey">Turkey</option>
                                                        <option value="Turkmenistan">Turkmenistan</option>
                                                        <option value="Turks and Caicos Islands">Turks and Caicos Islands</option>
                                                        <option value="Tuvalu">Tuvalu</option>
                                                        <option value="Uganda">Uganda</option>
                                                        <option value="Ukraine">Ukraine</option>
                                                        <option value="United Arab Emirates">United Arab Emirates</option>
                                                        <option value="United Kingdom">United Kingdom</option>
                                                        <option value="United States">United States</option>
                                                        <option value="United States Minor Outlying Islands">United States Minor Outlying Islands</option>
                                                        <option value="Uruguay">Uruguay</option>
                                                        <option value="Uzbekistan">Uzbekistan</option>
                                                        <option value="Vanuatu">Vanuatu</option>
                                                        <option value="Venezuela">Venezuela</option>
                                                        <option value="Viet Nam">Viet Nam</option>
                                                        <option value="Virgin Islands, British">Virgin Islands, British</option>
                                                        <option value="Virgin Islands, U.S.">Virgin Islands, U.S.</option>
                                                        <option value="Wallis and Futuna">Wallis and Futuna</option>
                                                        <option value="Western Sahara">Western Sahara</option>
                                                        <option value="Yemen">Yemen</option>
                                                        <option value="Zambia">Zambia</option>
                                                        <option value="Zimbabwe">Zimbabwe</option>
                                                    </Select>
                                                </div>
                                            </div>
                                            <div className="col2">
                                                <div className="form-field">
                                                    <label className="form-label" htmlFor>State/Province<span>*</span></label>
                                                    <Input
                                                        type="text"
                                                        id="state"
                                                        className="form-input"
                                                        name="state"
                                                        placeholder="NY"
                                                        value={bState}
                                                        onChange={onChangeBState}
                                                        validations={[required]}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-col2">
                                            <div className="col2">
                                                <div className="form-field">
                                                    <label className="form-label" htmlFor>City/Town<span>*</span></label>
                                                    <Input
                                                        type="text"
                                                        id="city"
                                                        className="form-input"
                                                        name="city"
                                                        placeholder="New York"
                                                        value={bCity}
                                                        onChange={onChangeBCity}
                                                        validations={[required]}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col2">
                                                <div className="form-field">
                                                    <label className="form-label" htmlFor>Zip/Postcode<span>*</span></label>
                                                    <Input
                                                        type="text"
                                                        id="zip"
                                                        className="form-input"
                                                        name="zip"
                                                        placeholder={10001}
                                                        value={bZipCode}
                                                        onChange={onChangeBZipCode}
                                                        validations={[required]}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-actions text-center">
                                            <button className="button btn-secondry" name="button" disabled={loading}>
                                                {loading && (
                                                    <i className="fa fa-refresh fa-spin" aria-hidden="true"/>
                                                )}
                                                <span>Save Changes</span>
                                            </button>
                                        </div>
                                        {message && (
                                            <div className="form-group">
                                                <div
                                                    className={"alert alert-success"}
                                                    role="alert"
                                                >
                                                    {message}
                                                </div>
                                            </div>
                                        )}
                                        <CheckButton style={{display: "none"}} ref={checkBtn}/>
                                    </div>
                                </Form>
                            </div>
                        </main>
                    </div>
                </div>
            </section>
        </Layout>
    );
}
export default Address;
