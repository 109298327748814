import React, {useState, useRef} from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import {isEmail} from "validator";
import AuthService from "../../services/auth.service";
import {Link} from "react-router-dom";
import Layout from "../MyLayout";

const required = (value) => {
    if (!value) {
        return (
            <span style={{display: 'block'}} className="form-inlineMessage error">
                This field is required!
            </span>
        );
    }
};
const validEmail = (value) => {
    if (!isEmail(value)) {
        return (
            <span style={{display: 'block'}} className="form-inlineMessage error">Please use a valid email address, such as user@example.com.</span>
        );
    }
};
const vpassword = (value) => {
    if (value.length < 6 || value.length > 40) {
        return (
            <div className="text-danger" role="alert">
                The password must be between 6 and 40 characters.
            </div>
        );
    }
};
const cPassword = (value, props, components) => {
    if (value !== components['password'][0].value) { // components['password'][0].value !== components['confirm'][0].value
        return <span className="error">Passwords are not equal.</span>
    }
};
const Register = (props) => {
    const form = useRef();
    const checkBtn = useRef();
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [cpassword, setCPassword] = useState("");
    const [successful, setSuccessful] = useState(false);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");
    const resetForm = () => {
        props.history.push('/login')
    }
    const onChangeFirstName = (e) => {
        const firstname = e.target.value;
        setFirstName(firstname);
    };
    const onChangeLastName = (e) => {
        const lastname = e.target.value;
        setLastName(lastname);
    };
    const onChangeEmail = (e) => {
        const email = e.target.value;
        setEmail(email);
    };
    const onChangePassword = (e) => {
        const password = e.target.value;
        setPassword(password);
    };
    const onChangeCPassword = (e) => {
        const cpassword = e.target.value;
        setCPassword(cpassword);
    };
    const handleRegister = (e) => {
        e.preventDefault();
        setMessage("");
        setSuccessful(false);
        setLoading(true);
        form.current.validateAll();
        if (checkBtn.current.context._errors.length === 0) {
            AuthService.register(firstName, lastName, email, password).then(
                (response) => {
                    setMessage(response.data.message);
                    setSuccessful(true);
                    setLoading(false);
                    resetForm()
                },
                (error) => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    setMessage(resMessage);
                    setSuccessful(false);
                    setLoading(false);
                }
            );
        }
    };
    return (
        <Layout>
            <section className="breadcrumbs">
                <div className="container">
                    <div className="breadcrumb">
                        <ul>
                            <li><Link to="/">Home</Link></li>
                            <li>Register</li>
                        </ul>
                    </div>
                </div>
            </section>
            <section className="login_page_Mn">
                <div className="container">
                    <div className="page_heading text-center">
                        <h1>Register</h1>
                    </div>
                    <div className="login_page_wrapper">
                        <Form onSubmit={handleRegister} ref={form}>
                            <div className="register_form form_Mn">
                                <div className="form-field">
                                    <label className="form-label" htmlFor="first_name">First Name<span>*</span></label>
                                    <Input
                                        type="text"
                                        className="form-input"
                                        name="first_name"
                                        placeholder="Enter First Name"
                                        value={firstName}
                                        onChange={onChangeFirstName}
                                        validations={[required]}
                                    />
                                </div>
                                <div className="form-field">
                                    <label className="form-label" htmlFor="last_name">Last Name<span>*</span></label>
                                    <Input
                                        type="text"
                                        className="form-input"
                                        name="last_name"
                                        placeholder="Enter Last Name"
                                        value={lastName}
                                        onChange={onChangeLastName}
                                        validations={[required]}
                                    />
                                </div>
                                <div className="form-field">
                                    <label className="form-label" htmlFor="reg_email">Email
                                        Address<span>*</span></label>
                                    <Input
                                        type="text"
                                        className="form-input"
                                        name="email"
                                        value={email}
                                        onChange={onChangeEmail}
                                        placeholder="Email"
                                        validations={[required, validEmail]}
                                    />
                                </div>
                                <div className="form-field">
                                    <label className="form-label" htmlFor="reg_pass">Password<span>*</span></label>
                                    <Input
                                        type="password"
                                        className="form-input"
                                        name="password"
                                        value={password}
                                        onChange={onChangePassword}
                                        validations={[required, vpassword]}
                                    />
                                </div>
                                <div className="form-field">
                                    <label className="form-label" htmlFor="reg_confirm_pass">Confirm
                                        Password<span>*</span></label>
                                    <Input
                                        type="password"
                                        className="form-input"
                                        name="cpassword"
                                        value={cpassword}
                                        onChange={onChangeCPassword}
                                        validations={[required, cPassword]}
                                    />
                                </div>
                                <div className="form-actions text-center">
                                    <button className="button btn-secondry" name="button" disabled={loading}>
                                        {loading && (
                                            <i className="fa fa-refresh fa-spin" aria-hidden="true"/>
                                        )}
                                        <span>Register</span>
                                    </button>
                                </div>
                                {message && (
                                    <div className="form-group">
                                        <div
                                            className={successful ? "alert alert-success" : "alert alert-danger"}
                                            role="alert"
                                        >
                                            {message}
                                        </div>
                                    </div>
                                )}
                                <CheckButton style={{display: "none"}} ref={checkBtn}/>
                                <div className="form-actions-alredy-customer text-center">
                                    <p className="alredy-customer-link">Already have an account? <Link to="/login">Log
                                        In</Link></p>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </section>
        </Layout>
    );
}
export default Register;
