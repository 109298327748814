import React, {useContext} from 'react';
import {PlusCircleIcon, MinusCircleIcon, TrashIcon} from '../icons'
import {CartContext} from '../../contexts/CartContext';
import {formatNumber} from '../../helpers/utils';

const CartItem = ({product, key}) => {
    const {increase, decrease, removeProduct, total} = useContext(CartContext);
    return (
        <tr className="cart-item" data-item-row key={key}>
            <td className="cart-item-block cart-item-figure">
                <img src={product.default_image} alt="prodthumb" title="prodthumb" className="cart-item-image"/>
            </td>
            <td className="cart-item-block cart-item-title">
                <h2 className="cart-item-name"><a href="javascript:void(0)">{product.name}</a></h2>
            </td>
            <td className="cart-item-block cart-item-info">
                <span className="cart-item-label">Price:</span>
                <span className="cart-item-value ">${product.price}</span>
            </td>
            <td className="cart-item-block cart-item-info cart-item-quantity">
                <label className="form-label cart-item-label" htmlFor="qty">Quantity:</label>
                <div className="form-increment">
                    <div className="quantity buttons_added">
                        {
                            product.quantity > 1 &&
                            <button
                                onClick={() => decrease(product)}
                                className="minus">
                                <MinusCircleIcon width={"20px"}/>
                            </button>
                        }
                        {
                            product.quantity === 1 &&
                            <button
                                onClick={() => removeProduct(product)}
                                className="minus">
                                <TrashIcon width={"20px"}/>
                            </button>
                        }
                        <input type="number" step={1} min={1} max name="quantity" value={product.quantity} title="Qty" className="input-text qty text" size={4} pattern inputMode/>
                        <button
                            onClick={() => increase(product)}
                            className="plus">
                            <PlusCircleIcon width={"20px"}/>
                        </button>
                    </div>
                </div>
            </td>
            <td className="cart-item-block cart-item-info">
                <button className="cart-remove icon" onClick={() => removeProduct(product)}><i className="fas fa-times"/></button>
            </td>
        </tr>
    );
}
export default CartItem;
