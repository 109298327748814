import React, {useContext, useEffect, useRef, useState} from 'react';
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import Textarea from "react-validation/build/textarea";
import CheckButton from "react-validation/build/button";
import {isEmail} from "validator";
import axios from "axios";
import {Link, useParams} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Slider from "react-slick";
import Layout, { siteURL } from "../MyLayout";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Rating from '@material-ui/lab/Rating';
import PropTypes from 'prop-types';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import {CartContext} from "../../contexts/CartContext";
import authHeader from "../../services/auth-header";
import {UpdateUserDetails, UpdateUserRating, ValidateZipCode} from "../../services/account.service";

const required = (value) => {
    if (!value) {
        return (
            <span style={{display: 'block'}} className="form-inlineMessage error">
                This field is required!
            </span>
        );
    }
};
const validEmail = (value) => {
    if (!isEmail(value)) {
        return (
            <span style={{display: 'block'}} className="form-inlineMessage error">Please use a valid email address, such as user@example.com.</span>
        );
    }
};
function TabPanel(props) {
    const {children, value, index, ...other} = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));
function ProductDetails(props) {
    const form = useRef();
    const checkBtn = useRef();
    const [firstName, setFirstName] = useState("");
    const [email, setEmail] = useState("");
    const [rating, setRating] = useState("");
    const [description, setDescription] = useState("");
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const {addProduct, increase, decrease, cartItems, removeProduct} = useContext(CartContext);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    var settingsOurProduct = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        autoplay: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    const [productId, setProductId] = useState("");
    const [productCount, setProductCount] = useState(1);
    const [productDetails, setProductDetails] = useState({});
    const [relatedProduct, setRelatedProduct] = useState([]);
    const [productReviwes, setProductReviwes] = useState([]);
    const [productImages, setProductImages] = useState([]);
    const [pinCode, setPinCode] = useState('');
    const [isValid, setValidPinCode] = useState(false);
    // retrieve params into a variable
    const params = useParams();
    useEffect(() => {
        setProductId(params.id)
        callAPI(params.id);
    }, []);
    /**
     * Product Details
     * */
    const callAPI = (pId) => {
        //making a get request with the fetch details API
        axios({
            method: 'get',
            url: `${siteURL}`+'product/' + pId,
            data: JSON.stringify({}),
            config: {headers: {'Content-Type': 'application/json', 'Accept': 'application/json'}}
        })
            .then((response) => {
                console.log('response.data', response.data);
                let product = response.data.products[0];
                product.price = product.price.slice(1)
                setProductDetails(product);
                setRelatedProduct(response.data.ralatedProduct);
                if (response && response.data && response.data.images) {
                    let images = response.data.images.map(item => {
                        let o = Object.assign({}, item);
                        o.original = o.image;
                        o.thumbnail = o.image;
                        delete o.image;
                        return o;
                    })
                    setProductImages(images);
                }
                setProductReviwes(response.data.reviwes);
            })
            .catch(function (response) {
                console.log(response);
            });
    }
    function dynamicHtml(html) {
        return {
            __html: html
        };
    }
    const IncrementItem = () => {
        setProductCount(productCount + 1);
    }
    const DecreaseItem = () => {
        if (productCount > 1) {
            decrease(productDetails);
            setProductCount(productCount - 1);
        } else {
            removeProduct(productDetails)
        }
    }
    const isInCart = product => {
        return !!cartItems.find(item => item.id === product.id);
    }
    const handelAddProductToWishlist = (item) => {
        axios.post(`${siteURL}`+'AddToWishList/', {product_id: item.id}, {
            headers: authHeader()
        }).then((response) => {
            callAPI();
        })
            .catch(function (response) {
                console.log(response);
            });
    };
    const onChangeFirstName = (e) => {
        const firstname = e.target.value;
        setFirstName(firstname);
    };
    const onChangeDescription = (e) => {
        const description = e.target.value;
        setDescription(description);
    };
    const onChangeEmail = (e) => {
        const email = e.target.value;
        setEmail(email);
    };
    const onChangeRating = (e) => {
        const rating = e.target.value;
        console.log(rating)
        setRating(rating);
    };
    const handleCheckPinCode = (event) => {
        setLoading(true);
        ValidateZipCode({zip_code: pinCode}).then(
            (response) => {
                console.log(response, 'response')
                if (response.status === 200) {
                    setValidPinCode(true)
                } else {
                    setValidPinCode(false)
                }
                setLoading(false);
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                setMessage(resMessage);
                setLoading(false);
            }
        );
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        setMessage("");
        setLoading(true);
        form.current.validateAll();
        if (checkBtn.current.context._errors.length === 0) {
            UpdateUserRating({product_id: productId, name: firstName, email: email, message: description, ratting: rating}).then(
                (response) => {
                    setMessage(response.message);
                    setLoading(false);
                    callAPI(productId);
                },
                (error) => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    setMessage(resMessage);
                    setLoading(false);
                }
            );
        }
    };
    return (
        <Layout>
            <section className="breadcrumbs">
                <div className="container">
                    <div className="breadcrumb">
                        <ul>
                            {/* <li><a href="/">Home</a></li> */}
                            <li><Link to="/">Home</Link></li>
                            {/* <li><a href="/">Men</a></li> */}
                            <li><Link to="/">Men</Link></li>
                            <li>{productDetails.title}</li>
                        </ul>
                    </div>
                </div>
            </section>
            <section className="prd_detailMn">
                <div className="container">
                    <div className="prd_detail_wrap">
                        <div className="prd_detail_info">
                            <div className="prd_detail_heading mobile_heading">
                                <h1>{productDetails.title}</h1>
                            </div>
                            <div className="prd_detail_left">
                                <ImageGallery
                                    items={productImages}
                                    showBullets={false}
                                    slideOnThumbnailHover={true}
                                />
                                {/*<div className="prod_view_mainimage">
                                    <figure>
                                        <a href="javascript:;"><img src={productDetails.default_image} alt="prodview"
                                                                    title="prodview"/></a>
                                    </figure>
                                </div>
                                <div className="prod_view_thumbimage">
                                    <ul className="thumbslider owl-carousel">
                                        <li>
                                            <figure><a href="javascript:;"><img src="images/prodthumb.jpg"
                                                                                alt="prodthumb" title="prodthumb"/></a>
                                            </figure>
                                        </li>
                                        <li>
                                            <figure><a href="javascript:;"><img src="images/prodthumb.jpg"
                                                                                alt="prodthumb" title="prodthumb"/></a>
                                            </figure>
                                        </li>
                                        <li>
                                            <figure><a href="javascript:;"><img src="images/prodthumb.jpg"
                                                                                alt="prodthumb" title="prodthumb"/></a>
                                            </figure>
                                        </li>
                                        <li>
                                            <figure><a href="javascript:;"><img src="images/prodthumb.jpg"
                                                                                alt="prodthumb" title="prodthumb"/></a>
                                            </figure>
                                        </li>
                                        <li>
                                            <figure><a href="javascript:;"><img src="images/prodthumb.jpg"
                                                                                alt="prodthumb" title="prodthumb"/></a>
                                            </figure>
                                        </li>
                                        <li>
                                            <figure><a href="javascript:;"><img src="images/prodthumb.jpg"
                                                                                alt="prodthumb" title="prodthumb"/></a>
                                            </figure>
                                        </li>
                                    </ul>
                                </div>*/}
                            </div>
                            <div className="prd_detail_right">
                                <div className="prd_detail_heading">
                                    <h1>{productDetails.title}</h1>
                                </div>
                                <div className="pd_reviewMn">
                                    <div className="prodgrid_star">
                                        <Rating name="half-rating-read" value={parseFloat(productDetails.avrageratting)}
                                                precision={0.5} readOnly/>
                                    </div>
                                    <div className="pd_rating_count">({productDetails.totalReview} Reviews)</div>
                                    <div className="pd_writereview"><a id="openreviewlink">Write a
                                        Review</a>
                                    </div>
                                </div>
                                <div className="pd_detailsWrp">
                                    <div className="product-grid_price">
                                        {productDetails.discount ? <>
                                                <span className="discounted-price">${productDetails.price}</span>
                                                <span
                                                    className="main-price discounted">${productDetails.discounted_price}</span>
                                                <span className="yousave-price">${productDetails.discount}% Off</span>
                                            </> :
                                            <span className="discounted-price">${productDetails.price}</span>}
                                    </div>
                                    <div className="pd_details_info">
                                        <div className="pd_details_row">
                                            <div className="left_dt">Product ID:</div>
                                            <div className="right_dd">00129</div>
                                        </div>
                                        <div className="pd_details_row">
                                            <div className="left_dt">Shipping:</div>
                                            <div className="right_dd">$10.00</div>
                                        </div>
                                        <div className="pd_details_row">
                                            <div className="left_dt">Delivery:</div>
                                            <div className="right_dd">5-7 Business Days</div>
                                        </div>
                                        <div className="pd_details_row">
                                            <div className="left_dt">Quantity:</div>
                                            <div className="right_dd">
                                                <div className="quantity buttons_added">
                                                    <input type="number" step={1} min={1} name="quantity"
                                                           defaultValue={productCount} value={productCount} title="Qty"
                                                           className="input-text qty text"
                                                           size={4} pattern inputMode/>
                                                    {
                                                        isInCart(productDetails) ?
                                                            <>
                                                                <button className="minus" onClick={() => {
                                                                    DecreaseItem();
                                                                }}><i className="fas fa-minus"/></button>
                                                                <button className="plus" onClick={() => {
                                                                    increase(productDetails);
                                                                    IncrementItem();
                                                                }}><i className="fas fa-plus"/></button>
                                                            </> :
                                                            <>
                                                                <button className="minus"><i className="fas fa-minus"/></button>
                                                                <button className="plus" onClick={() => addProduct(productDetails)}><i className="fas fa-plus"/></button>
                                                            </>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pd_actions">
                                        <div className="btn-groups-wrp">
                                            {
                                                isInCart(productDetails) &&
                                                <Link className="btn-secondry" to={'/checkout'}>Buy Now</Link>
                                            } {
                                            !isInCart(productDetails) &&
                                            <button type="button" className="btn-secondry" onClick={() => {
                                                addProduct(productDetails)
                                                props.history.push('/checkout')
                                            }}>Buy Now</button>
                                        }
                                        </div>
                                        <div className="btn-groups-wrp">
                                            {
                                                isInCart(productDetails) &&
                                                <button type="button" className="btn-primary" onClick={() => increase(productDetails)}>Add more</button>
                                            }
                                            {
                                                !isInCart(productDetails) &&
                                                <button type="button" className="btn-primary" onClick={() => addProduct(productDetails)}>Add to Cart</button>
                                            }
                                        </div>
                                        <div className="btn-groups-wrp">
                                            <button className="btn-wishlist" onClick={() => handelAddProductToWishlist(productDetails)}><i className="far fa-heart"/>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="pd_info_list">
                                        <ul>
                                            <li>
                                                <div className="icon"><i className="far fa-eye"/></div>
                                                <div className="content">140 Viewing This Product</div>
                                            </li>
                                            <li>
                                                <div className="icon"><i className="far fa-check-circle"/></div>
                                                <div className="content">Verified Purchase (994)</div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="pincode_box">
                                        <input type="text" placeholder="Enter Pincode for delivery" onChange={(e) => setPinCode(e.target.value)} name="pincode"/>
                                        <input type="submit" defaultValue="Check" onClick={(e) => handleCheckPinCode(e)}/>
                                        {isValid ? <div className="form-inlineMessage success" style={{display: 'block'}}><i
                                            className="far fa-check-circle"/> COD is available on {pinCode}
                                        </div> : <div className="form-inlineMessage error" style={{display: 'block'}}><i
                                            className="fas fa-exclamation-circle"/> Please enter a valid pincode.
                                        </div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="prod_detail_tabs" className="prd_detail_tabing">
                            <Paper position="static">
                                <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                                    <Tab label="Description" {...a11yProps(0)} />
                                    <Tab label="Specifications" {...a11yProps(1)} />
                                    <Tab label="Reviews" {...a11yProps(2)} />
                                </Tabs>
                            </Paper>
                            <TabPanel value={value} index={0}>
                                <div id="description"
                                     dangerouslySetInnerHTML={dynamicHtml(productDetails.description)}/>
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <div id="specifications"
                                     dangerouslySetInnerHTML={dynamicHtml(productDetails.specification)}/>
                            </TabPanel>
                            <TabPanel value={value} index={2}>
                                <div id="reviews">
                                    <div id="openreviewtab"/>
                                    <div className="customer-review-Mn">
                                        <div className="customer-review-head">
                                            <h2 className="customer-review-tilte">{productReviwes.length} Reviews</h2>
                                        </div>
                                        <div className="customer-review-lists">
                                            {productReviwes
                                            && productReviwes.length
                                                ? productReviwes.map(item => {
                                                    return <div className="customer-review">
                                                        <h4 className="name">{item.name}</h4>
                                                        <div className="customer-review-wrap">
                                                            <Rating
                                                                name="simple-controlled"
                                                                value={item.ratting}
                                                                readOnly
                                                            />
                                                            <span className="date">{item.created_at}</span>
                                                        </div>
                                                        <p className="content">{item.message}</p>
                                                    </div>
                                                }) : ""}
                                        </div>
                                    </div>
                                    <div className="prod-reviewsform">
                                        <h2>Add a Review</h2>
                                        <div className="form_Mn">
                                            <Form onSubmit={handleSubmit} ref={form}>
                                                <div className="form-field">
                                                    <label className="form-label" htmlFor>Full Name</label>
                                                    <Input
                                                        type="text"
                                                        className="form-input"
                                                        name="first_name"
                                                        placeholder="Enter First Name"
                                                        value={firstName}
                                                        onChange={onChangeFirstName}
                                                        validations={[required]}
                                                    />
                                                </div>
                                                <div className="form-field">
                                                    <label className="form-label" htmlFor>Email
                                                        Address<span>*</span></label>
                                                    <Input
                                                        type="text"
                                                        className="form-input"
                                                        name="email"
                                                        value={email}
                                                        onChange={onChangeEmail}
                                                        placeholder="Email"
                                                        validations={[required, validEmail]}
                                                    />
                                                </div>
                                                <div className="form-field">
                                                    <label className="form-label" htmlFor>Message<span>*</span></label>
                                                    <Textarea
                                                        placeholder="Leave your message..."
                                                        className="form-textarea"
                                                        onChange={onChangeDescription}
                                                        name="description"
                                                        validations={[required]}
                                                    />
                                                </div>
                                                <div className="form-field">
                                                    <label className="form-label" htmlFor>Select
                                                        Rating<span>*</span></label>
                                                    <div className="star-rating">
                                                        <Input
                                                            type="radio"
                                                            id="5-stars" name="rating"
                                                            value={5}
                                                            onChange={onChangeRating}
                                                        />
                                                        <label htmlFor="5-stars" className="star">★</label>
                                                        <Input
                                                            type="radio"
                                                            id="4-stars" name="rating"
                                                            value={4}
                                                            onChange={onChangeRating}
                                                        />
                                                        <label htmlFor="4-stars" className="star">★</label>
                                                        <Input
                                                            type="radio"
                                                            id="3-stars" name="rating"
                                                            value={3}
                                                            onChange={onChangeRating}
                                                        />
                                                        <label htmlFor="3-stars" className="star">★</label>
                                                        <Input
                                                            type="radio"
                                                            id="2-stars" name="rating"
                                                            value={2}
                                                            onChange={onChangeRating}
                                                        />
                                                        <label htmlFor="2-stars" className="star">★</label>
                                                        <Input
                                                            type="radio"
                                                            id="1-stars" name="rating"
                                                            value={1}
                                                            onChange={onChangeRating}
                                                        />
                                                        <label htmlFor="1-star" className="star">★</label>
                                                    </div>
                                                </div>
                                                {message && (
                                                    <div className="form-group">
                                                        <div
                                                            className={"alert alert-success"}
                                                            role="alert"
                                                        >
                                                            {message}
                                                        </div>
                                                    </div>
                                                )}
                                                <CheckButton style={{display: "none"}} ref={checkBtn}/>
                                                <div className="form-actions text-center">
                                                    <button className="button btn-secondry" name="button" disabled={loading}>
                                                        {loading && (
                                                            <i className="fa fa-refresh fa-spin" aria-hidden="true"/>
                                                        )}
                                                        <span>Submit</span>
                                                    </button>
                                                </div>
                                            </Form>
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                        </div>
                        <div className="prd_detail_relatedMn">
                            <div className="home-section-inner">
                                <div className="home-section-head wow fadeInDown">
                                    <h2>RELATED PRODUCTS</h2>
                                    <em>Check items to add to the cart</em>
                                </div>
                            </div>
                            <div className="product_grid prd_detail_related home_newarrival">
                                <Slider {...settingsOurProduct}>
                                    {relatedProduct
                                    && relatedProduct.length
                                    && relatedProduct.map(p => {
                                            return <div>
                                                <article className="product-grid_artcile">
                                                    <figure className="product-grid-figure">
                                                        <Link className="product-grid-image-wrap" to="/products">
                                                            <img src={p.default_image} alt={p.title} title={p.title}/>
                                                        </Link>
                                                        <figcaption className="product-grid-figcaption">
                                                            <div className="product-grid_floating-badges">
                                                                <span className="new badge">New</span>
                                                            </div>
                                                            <div className="product-grid_floating-icons">
                                                                <div className="wishlist_floating">
                                                                    <a href="javascript:void(0);">
                                                                        <i className="far fa-heart"/>
                                                                    </a>
                                                                </div>
                                                                <div className="addtocart_floating"><a href="javascript:void(0)">Add to
                                                                    Cart</a></div>
                                                            </div>
                                                        </figcaption>
                                                    </figure>
                                                    <div className="product-grid_content">
                                                        <div className="title">
                                                            <h3><Link to="/products">{p.title}</Link></h3>
                                                        </div>
                                                        <div className="product-grid_rating">
                                                            <div className="prodgrid_star">
                                                                <ul>
                                                                    <li><a href="javascript:void(0)"><i className="fas fa-star"></i></a></li>
                                                                    <li><a href="javascript:void(0)"><i className="fas fa-star"></i></a></li>
                                                                    <li><a href="javascript:void(0)"><i className="fas fa-star"></i></a></li>
                                                                    <li><a href="javascript:void(0)"><i className="fas fa-star"></i></a></li>
                                                                    <li><a href="javascript:void(0)"><i className="far fa-star"></i></a></li>
                                                                </ul>
                                                            </div>
                                                            <div className="prodgrid-rating_count">(45)</div>
                                                        </div>
                                                        <div className="product-grid_price">
                                                            {p.discount > 0 ?
                                                                <>
                                                                    <span
                                                                        className="discounted-price">${p.discounted_price}</span>
                                                                    <span
                                                                        className="main-price discounted">${p.price}</span>
                                                                    <span
                                                                        className="yousave-price">${p.discount}% Off</span>
                                                                </>
                                                                : <span className="discounted-price">${p.price}</span>}
                                                        </div>
                                                    </div>
                                                </article>
                                            </div>
                                        }
                                    )}
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}
export default ProductDetails;
