import React, {useEffect, useState, useContext, useRef} from 'react';
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import Select from "react-validation/build/select";
import CheckButton from "react-validation/build/button";
import {isEmail} from "validator";
import Layout from "../MyLayout";
import {Link} from "react-router-dom";
import {CartContext} from "../../contexts/CartContext";
import CheckoutItem from "./CheckoutItem";
import {loadStripe} from '@stripe/stripe-js';
import {isLogin} from "../../helpers/utils";
import AuthService from "../../services/auth.service";
import {checkoutPayment, placeOrder} from "../../services/cart.service";
import {GetUserAddress, ValidateZipCode} from "../../services/account.service";
import {Snackbar} from "@material-ui/core";
import {Alert} from "@material-ui/lab";

const stripePromise = loadStripe('pk_test_51IH1JzKf5rxyA9eOhuyQjuHdZOrCrZAJ096dOdUsCxSt9K6Ow31gpXXpkmFqaxG6A8BNEtYZCQqGKsx35S6Ai8Jo004g9YEcC3');
const required = (value) => {
    if (!value) {
        return (
            <div className="text-danger font-weight-bold" style={{fontSize: '15px'}}>
                This field is required!
            </div>
        );
    }
};
const email = value => {
    if (!isEmail(value)) {
        return (
            <div className="text-danger font-weight-bold font-size" style={{fontSize: '15px'}}>
                This is not a valid email.
            </div>
        );
    }
};
function Checkout(props) {
    const form = useRef();
    const checkBtn = useRef();
    const {cartItems, total, handleCheckout, checkout} = useContext(CartContext);
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [paymentMode, setPaymentMode] = useState('cod');
    /**
     * Billing Address
     */
    const [bFirstName, setBFirstName] = useState('');
    const [bLastName, setBLastName] = useState('');
    const [bEmail, setBEmail] = useState('');
    const [bMobile, setBMobile] = useState('');
    const [bAddressLine1, setBAddressLine1] = useState('');
    const [bAddressLine2, setBAddressLine2] = useState('');
    const [bCity, setBCity] = useState('');
    const [bState, setBState] = useState('');
    const [bCountry, setBCountry] = useState('');
    const [bZipCode, setBZipCode] = useState('');
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [isValid, setValidPinCode] = useState(false);
    /**
     * Form Validation Handel*/
    const onChangeBFirstName = (e) => {
        const BFirstName = e.target.value;
        setBFirstName(BFirstName);
    };
    const onChangeBLastName = (e) => {
        const BLastName = e.target.value;
        setBLastName(BLastName);
    };
    const onChangeBEmail = (e) => {
        const BEmail = e.target.value;
        setBEmail(BEmail);
    };
    const onChangeBMobile = (e) => {
        const BMobile = e.target.value;
        setBMobile(BMobile);
    };
    const onChangeBAddressLine1 = (e) => {
        const BAddressLine1 = e.target.value;
        setBAddressLine1(BAddressLine1);
    };
    const onChangeBAddressLine2 = (e) => {
        const BAddressLine2 = e.target.value;
        setBAddressLine2(BAddressLine2);
    };
    const onChangeBCity = (e) => {
        const BCity = e.target.value;
        setBCity(BCity);
    };
    const onChangeBState = (e) => {
        const BState = e.target.value;
        setBState(BState);
    };
    const onChangeBCountry = (e) => {
        const BCountry = e.target.value;
        setBCountry(BCountry);
    };
    const onChangeBZipCode = (e) => {
        const BZipCode = e.target.value;
        handleCheckPinCode(BZipCode)
        setBZipCode(BZipCode);
    };
    useEffect(() => {
        // Check to see if this is a redirect back from Checkout
        const query = new URLSearchParams(window.location.search);
        if (query.get("success")) {
            setSuccess(true)
            handleCheckout()
            setMessage("Order placed! You will receive an email confirmation.");
        }
        if (query.get("canceled")) {
            setError(true)
            setMessage(
                "Order canceled -- continue to shop around and checkout when you're ready."
            );
        }
    }, []);
    useEffect(() => {
        GetUserAddress().then(
            (response) => {
                if (response && response.data) {
                    let responseData = response.data;
                    setBFirstName(responseData.first_name)
                    setBLastName(responseData.last_name)
                    setBEmail(responseData.email)
                    setBMobile(responseData.mobile)
                    setBAddressLine1(responseData.address_1)
                    setBAddressLine2(responseData.address_2)
                    setBCountry(responseData.country)
                    setBState(responseData.state)
                    setBCity(responseData.city)
                    setBZipCode(responseData.zip_code)
                }
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                setMessage(resMessage);
            }
        );
    }, [])
    /**
     * Place Order
     */
    const handleSubmit = async (event) => {
        // Block native form submission.
        event.preventDefault();
        setMessage("");
        setLoading(true);
        form.current.validateAll();
        if (checkBtn.current.context._errors.length === 0) {
            if (paymentMode === 'cod') {
                const requestPayload = {
                    BillTotal: total,
                    Status: 'Placed',
                    BillingAddress: {
                        first_name: bFirstName,
                        last_name: bLastName,
                        email: bEmail,
                        mobile: bMobile,
                        address_1: bAddressLine1,
                        address_2: bAddressLine2,
                        city: bCity,
                        state: bState,
                        country: bCountry,
                        zipCode: bZipCode
                    },
                    Items: cartItems
                };
                const response = await placeOrder(requestPayload, {
                    method: "POST",
                });
                const session = await response;
                if (session.status === 401) {
                    AuthService.logout()
                    props.history.push("/login");
                }
                console.log(session, 'placeHolder')
                if (session) {
                    handleCheckout()
                    setSuccess(true)
                    setMessage("Order placed! You will receive an email confirmation.");
                    setLoading(false);
                }
                setSuccess(true)
                setMessage("Order placed! You will receive an email confirmation.");
                return false;
            }
            const stripe = await stripePromise;
            const response = await checkoutPayment({UserId: AuthService.getCurrentUser()?._id}, {
                method: "POST",
            });
            const session = await response;
            if (session.status === 401) {
                AuthService.logout()
                props.history.push("/login");
            }
            console.log('session-stripe-payment', session);
            // When the customer clicks on the button, redirect them to Checkout.
            const result = await stripe.redirectToCheckout({
                sessionId: session.id,
            });
            if (result.error) {
                setError(true)
                setMessage('Something Went Wrong')
                // If `redirectToCheckout` fails due to a browser or network
                // error, display the localized error message to your customer
                // using `result.error.message`.
            }
            // TODO API Call
        } else {
            setLoading(false);
        }
    };
    const handleClose = () => {
        setError(false)
        setSuccess(false)
    }
    const handleCheckPinCode = (zip_code) => {
        setLoading(true);
        ValidateZipCode({zip_code: zip_code}).then(
            (response) => {
                console.log(response, 'response')
                if (response.status === 200) {
                    setValidPinCode(true)
                } else {
                    setValidPinCode(false)
                }
                setLoading(false);
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                setMessage(resMessage);
                setLoading(false);
            }
        );
    }
    return (
        <Layout>
            <section className="breadcrumbs">
                <div className="container">
                    <div className="breadcrumb">
                        <ul>
                            <li><Link to="/">Home</Link></li>
                            <li>Checkout</li>
                        </ul>
                    </div>
                </div>
            </section>
            <section className="checkout_Mn">
                <div className="container">
                    <div className="page_heading text-center">
                        <h1>Checkout</h1>
                    </div>
                    <Snackbar anchorOrigin={{vertical: 'top', horizontal: 'center'}} open={error ? error : success} autoHideDuration={6000} onClose={handleClose}>
                        <Alert onClose={handleClose} severity={error ? 'error' : 'success'}>
                            {message}
                        </Alert>
                    </Snackbar>
                    <Form onSubmit={handleSubmit} ref={form}>
                        <div className="checkout_wrap">
                            <div className="checkout_colmnMn">
                                <div className="checkout_colmnWr">
                                    {isLogin() ? "" : <div className="checkout-customer-login"><p>Already have an account? <Link to="/login">Sign in now</Link></p></div>}
                                    <h2>Shipping</h2>
                                    <div className="checkout-form">
                                        <div className="form-col2">
                                            <div className="col2">
                                                <div className="form-field">
                                                    <label className="form-label" htmlFor>First Name<span>*</span></label>
                                                    <Input
                                                        type="text"
                                                        id="firstname"
                                                        className="form-input"
                                                        name="firstname"
                                                        placeholder="John M"
                                                        value={bFirstName}
                                                        onChange={onChangeBFirstName}
                                                        validations={[required]}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col2">
                                                <div className="form-field">
                                                    <label className="form-label" htmlFor>Last Name<span>*</span></label>
                                                    <Input
                                                        type="text"
                                                        id="lastname"
                                                        className="form-input"
                                                        name="lastname"
                                                        placeholder="Doe"
                                                        value={bLastName}
                                                        onChange={onChangeBLastName}
                                                        validations={[required]}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-field">
                                            <label className="form-label" htmlFor>Email Address<span>*</span></label>
                                            <Input
                                                type="text"
                                                id="email"
                                                className="form-input"
                                                name="email"
                                                value={bEmail}
                                                placeholder="john@example.com"
                                                onChange={onChangeBEmail}
                                                validations={[required, email]}
                                            />
                                        </div>
                                        <div className="form-field">
                                            <label className="form-label" htmlFor>Phone Number<span>*</span></label>
                                            <Input
                                                type="text"
                                                id="mobile"
                                                className="form-input"
                                                name="mobile"
                                                value={bMobile}
                                                placeholder="1234567890"
                                                onChange={onChangeBMobile}
                                                validations={[required]}
                                            />
                                        </div>
                                        <div className="form-field">
                                            <label className="form-label" htmlFor>Address Line 1<span>*</span></label>
                                            <Input
                                                type="text"
                                                id="adr"
                                                name="addressLine1"
                                                className="form-input"
                                                placeholder="Address Line 1"
                                                value={bAddressLine1}
                                                onChange={onChangeBAddressLine1}
                                                validations={[required]}
                                            />
                                        </div>
                                        <div className="form-field">
                                            <label className="form-label" htmlFor>Address Line 2<span>*</span></label>
                                            <Input
                                                type="text"
                                                id="adr"
                                                className="form-input"
                                                name="addressLine2"
                                                placeholder="Address Line 2"
                                                value={bAddressLine2}
                                                onChange={onChangeBAddressLine2}
                                                validations={[required]}
                                            />
                                        </div>
                                        <div className="form-col2">
                                            <div className="col2">
                                                <div className="form-field">
                                                    <label className="form-label" htmlFor>Country<span>*</span></label>
                                                    <Select name='country' className="form-select" value={bCountry} onChange={onChangeBCountry} validations={[required]}>
                                                        <option selected value>Choose a Country</option>
                                                        <option value="Afghanistan">Afghanistan</option>
                                                        <option value="Albania">Albania</option>
                                                        <option value="Algeria">Algeria</option>
                                                        <option value="American Samoa">American Samoa</option>
                                                        <option value="Andorra">Andorra</option>
                                                        <option value="Angola">Angola</option>
                                                        <option value="Anguilla">Anguilla</option>
                                                        <option value="Antarctica">Antarctica</option>
                                                        <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                                                        <option value="Argentina">Argentina</option>
                                                        <option value="Armenia">Armenia</option>
                                                        <option value="Aruba">Aruba</option>
                                                        <option value="Australia">Australia</option>
                                                        <option value="Austria">Austria</option>
                                                        <option value="Azerbaijan">Azerbaijan</option>
                                                        <option value="Bahamas">Bahamas</option>
                                                        <option value="Bahrain">Bahrain</option>
                                                        <option value="Bangladesh">Bangladesh</option>
                                                        <option value="Barbados">Barbados</option>
                                                        <option value="Belarus">Belarus</option>
                                                        <option value="Belgium">Belgium</option>
                                                        <option value="Belize">Belize</option>
                                                        <option value="Benin">Benin</option>
                                                        <option value="Bermuda">Bermuda</option>
                                                        <option value="Bhutan">Bhutan</option>
                                                        <option value="Bolivia">Bolivia</option>
                                                        <option value="Bonaire, Sint Eustatius and Saba">Bonaire, Sint Eustatius and Saba</option>
                                                        <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                                                        <option value="Botswana">Botswana</option>
                                                        <option value="Bouvet Island">Bouvet Island</option>
                                                        <option value="Brazil">Brazil</option>
                                                        <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
                                                        <option value="Brunei Darussalam">Brunei Darussalam</option>
                                                        <option value="Bulgaria">Bulgaria</option>
                                                        <option value="Burkina Faso">Burkina Faso</option>
                                                        <option value="Burundi">Burundi</option>
                                                        <option value="Cambodia">Cambodia</option>
                                                        <option value="Cameroon">Cameroon</option>
                                                        <option value="Canada">Canada</option>
                                                        <option value="Cape Verde">Cape Verde</option>
                                                        <option value="Cayman Islands">Cayman Islands</option>
                                                        <option value="Central African Republic">Central African Republic</option>
                                                        <option value="Chad">Chad</option>
                                                        <option value="Chile">Chile</option>
                                                        <option value="China">China</option>
                                                        <option value="Christmas Island">Christmas Island</option>
                                                        <option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
                                                        <option value="Colombia">Colombia</option>
                                                        <option value="Comoros">Comoros</option>
                                                        <option value="Congo">Congo</option>
                                                        <option value="Congo, the Democratic Republic of the">Congo, the Democratic Republic of the</option>
                                                        <option value="Cook Islands">Cook Islands</option>
                                                        <option value="Costa Rica">Costa Rica</option>
                                                        <option value="Cote d'Ivoire">Cote d'Ivoire</option>
                                                        <option value="Croatia">Croatia</option>
                                                        <option value="Cyprus">Cyprus</option>
                                                        <option value="Czech Republic">Czech Republic</option>
                                                        <option value="Denmark">Denmark</option>
                                                        <option value="Djibouti">Djibouti</option>
                                                        <option value="Dominica">Dominica</option>
                                                        <option value="Dominican Republic">Dominican Republic</option>
                                                        <option value="Ecuador">Ecuador</option>
                                                        <option value="Egypt">Egypt</option>
                                                        <option value="El Salvador">El Salvador</option>
                                                        <option value="Equatorial Guinea">Equatorial Guinea</option>
                                                        <option value="Eritrea">Eritrea</option>
                                                        <option value="Estonia">Estonia</option>
                                                        <option value="Ethiopia">Ethiopia</option>
                                                        <option value="Falkland Islands (Malvinas)">Falkland Islands (Malvinas)</option>
                                                        <option value="Faroe Islands">Faroe Islands</option>
                                                        <option value="Fiji">Fiji</option>
                                                        <option value="Finland">Finland</option>
                                                        <option value="France">France</option>
                                                        <option value="French Guiana">French Guiana</option>
                                                        <option value="French Polynesia">French Polynesia</option>
                                                        <option value="French Southern Territories">French Southern Territories</option>
                                                        <option value="Gabon">Gabon</option>
                                                        <option value="Gambia">Gambia</option>
                                                        <option value="Georgia">Georgia</option>
                                                        <option value="Germany">Germany</option>
                                                        <option value="Ghana">Ghana</option>
                                                        <option value="Gibraltar">Gibraltar</option>
                                                        <option value="Greece">Greece</option>
                                                        <option value="Greenland">Greenland</option>
                                                        <option value="Grenada">Grenada</option>
                                                        <option value="Guadeloupe">Guadeloupe</option>
                                                        <option value="Guam">Guam</option>
                                                        <option value="Guatemala">Guatemala</option>
                                                        <option value="Guernsey">Guernsey</option>
                                                        <option value="Guinea">Guinea</option>
                                                        <option value="Guinea-Bissau">Guinea-Bissau</option>
                                                        <option value="Guyana">Guyana</option>
                                                        <option value="Haiti">Haiti</option>
                                                        <option value="Heard Island and Mcdonald Islands">Heard Island and Mcdonald Islands</option>
                                                        <option value="Holy See (Vatican City State)">Holy See (Vatican City State)</option>
                                                        <option value="Honduras">Honduras</option>
                                                        <option value="Hong Kong">Hong Kong</option>
                                                        <option value="Hungary">Hungary</option>
                                                        <option value="Iceland">Iceland</option>
                                                        <option value="India">India</option>
                                                        <option value="Indonesia">Indonesia</option>
                                                        <option value="Iraq">Iraq</option>
                                                        <option value="Ireland">Ireland</option>
                                                        <option value="Isle of Man">Isle of Man</option>
                                                        <option value="Israel">Israel</option>
                                                        <option value="Italy">Italy</option>
                                                        <option value="Jamaica">Jamaica</option>
                                                        <option value="Japan">Japan</option>
                                                        <option value="Jersey">Jersey</option>
                                                        <option value="Jordan">Jordan</option>
                                                        <option value="Kazakhstan">Kazakhstan</option>
                                                        <option value="Kenya">Kenya</option>
                                                        <option value="Kiribati">Kiribati</option>
                                                        <option value="Korea, Republic of">Korea, Republic of</option>
                                                        <option value="Kuwait">Kuwait</option>
                                                        <option value="Kyrgyzstan">Kyrgyzstan</option>
                                                        <option value="Lao People's Democratic Republic">Lao People's Democratic Republic</option>
                                                        <option value="Latvia">Latvia</option>
                                                        <option value="Lebanon">Lebanon</option>
                                                        <option value="Lesotho">Lesotho</option>
                                                        <option value="Liberia">Liberia</option>
                                                        <option value="Libya">Libya</option>
                                                        <option value="Liechtenstein">Liechtenstein</option>
                                                        <option value="Lithuania">Lithuania</option>
                                                        <option value="Luxembourg">Luxembourg</option>
                                                        <option value="Macao">Macao</option>
                                                        <option value="Macedonia, the Former Yugoslav Republic of">Macedonia, the Former Yugoslav Republic of</option>
                                                        <option value="Madagascar">Madagascar</option>
                                                        <option value="Malawi">Malawi</option>
                                                        <option value="Malaysia">Malaysia</option>
                                                        <option value="Maldives">Maldives</option>
                                                        <option value="Mali">Mali</option>
                                                        <option value="Malta">Malta</option>
                                                        <option value="Marshall Islands">Marshall Islands</option>
                                                        <option value="Martinique">Martinique</option>
                                                        <option value="Mauritania">Mauritania</option>
                                                        <option value="Mauritius">Mauritius</option>
                                                        <option value="Mayotte">Mayotte</option>
                                                        <option value="Mexico">Mexico</option>
                                                        <option value="Micronesia, Federated States of">Micronesia, Federated States of</option>
                                                        <option value="Moldova, Republic of">Moldova, Republic of</option>
                                                        <option value="Monaco">Monaco</option>
                                                        <option value="Mongolia">Mongolia</option>
                                                        <option value="Montenegro">Montenegro</option>
                                                        <option value="Montserrat">Montserrat</option>
                                                        <option value="Morocco">Morocco</option>
                                                        <option value="Mozambique">Mozambique</option>
                                                        <option value="Myanmar">Myanmar</option>
                                                        <option value="Namibia">Namibia</option>
                                                        <option value="Nauru">Nauru</option>
                                                        <option value="Nepal">Nepal</option>
                                                        <option value="Netherlands">Netherlands</option>
                                                        <option value="Netherlands Antilles">Netherlands Antilles</option>
                                                        <option value="New Caledonia">New Caledonia</option>
                                                        <option value="New Zealand">New Zealand</option>
                                                        <option value="Nicaragua">Nicaragua</option>
                                                        <option value="Niger">Niger</option>
                                                        <option value="Nigeria">Nigeria</option>
                                                        <option value="Niue">Niue</option>
                                                        <option value="Norfolk Island">Norfolk Island</option>
                                                        <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                                                        <option value="Norway">Norway</option>
                                                        <option value="Oman">Oman</option>
                                                        <option value="Pakistan">Pakistan</option>
                                                        <option value="Palau">Palau</option>
                                                        <option value="Palestinian Territory, Occupied">Palestinian Territory, Occupied</option>
                                                        <option value="Panama">Panama</option>
                                                        <option value="Papua New Guinea">Papua New Guinea</option>
                                                        <option value="Paraguay">Paraguay</option>
                                                        <option value="Peru">Peru</option>
                                                        <option value="Philippines">Philippines</option>
                                                        <option value="Pitcairn">Pitcairn</option>
                                                        <option value="Poland">Poland</option>
                                                        <option value="Portugal">Portugal</option>
                                                        <option value="Puerto Rico">Puerto Rico</option>
                                                        <option value="Qatar">Qatar</option>
                                                        <option value="Republic of Kosovo">Republic of Kosovo</option>
                                                        <option value="Reunion">Reunion</option>
                                                        <option value="Romania">Romania</option>
                                                        <option value="Russian Federation">Russian Federation</option>
                                                        <option value="Rwanda">Rwanda</option>
                                                        <option value="Saint Helena">Saint Helena</option>
                                                        <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                                                        <option value="Saint Lucia">Saint Lucia</option>
                                                        <option value="Saint Pierre and Miquelon">Saint Pierre and Miquelon</option>
                                                        <option value="Saint Vincent and the Grenadines">Saint Vincent and the Grenadines</option>
                                                        <option value="Samoa">Samoa</option>
                                                        <option value="San Marino">San Marino</option>
                                                        <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                                                        <option value="Saudi Arabia">Saudi Arabia</option>
                                                        <option value="Senegal">Senegal</option>
                                                        <option value="Serbia">Serbia</option>
                                                        <option value="Seychelles">Seychelles</option>
                                                        <option value="Sierra Leone">Sierra Leone</option>
                                                        <option value="Singapore">Singapore</option>
                                                        <option value="Slovakia">Slovakia</option>
                                                        <option value="Slovenia">Slovenia</option>
                                                        <option value="Solomon Islands">Solomon Islands</option>
                                                        <option value="Somalia">Somalia</option>
                                                        <option value="South Africa">South Africa</option>
                                                        <option value="South Georgia and the South Sandwich Islands">South Georgia and the South Sandwich Islands</option>
                                                        <option value="Spain">Spain</option>
                                                        <option value="Sri Lanka">Sri Lanka</option>
                                                        <option value="Sudan">Sudan</option>
                                                        <option value="Suriname">Suriname</option>
                                                        <option value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</option>
                                                        <option value="Swaziland">Swaziland</option>
                                                        <option value="Sweden">Sweden</option>
                                                        <option value="Switzerland">Switzerland</option>
                                                        <option value="Taiwan">Taiwan</option>
                                                        <option value="Tajikistan">Tajikistan</option>
                                                        <option value="Tanzania, United Republic of">Tanzania, United Republic of</option>
                                                        <option value="Thailand">Thailand</option>
                                                        <option value="Timor-Leste">Timor-Leste</option>
                                                        <option value="Togo">Togo</option>
                                                        <option value="Tokelau">Tokelau</option>
                                                        <option value="Tonga">Tonga</option>
                                                        <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                                                        <option value="Tunisia">Tunisia</option>
                                                        <option value="Turkey">Turkey</option>
                                                        <option value="Turkmenistan">Turkmenistan</option>
                                                        <option value="Turks and Caicos Islands">Turks and Caicos Islands</option>
                                                        <option value="Tuvalu">Tuvalu</option>
                                                        <option value="Uganda">Uganda</option>
                                                        <option value="Ukraine">Ukraine</option>
                                                        <option value="United Arab Emirates">United Arab Emirates</option>
                                                        <option value="United Kingdom">United Kingdom</option>
                                                        <option value="United States">United States</option>
                                                        <option value="United States Minor Outlying Islands">United States Minor Outlying Islands</option>
                                                        <option value="Uruguay">Uruguay</option>
                                                        <option value="Uzbekistan">Uzbekistan</option>
                                                        <option value="Vanuatu">Vanuatu</option>
                                                        <option value="Venezuela">Venezuela</option>
                                                        <option value="Viet Nam">Viet Nam</option>
                                                        <option value="Virgin Islands, British">Virgin Islands, British</option>
                                                        <option value="Virgin Islands, U.S.">Virgin Islands, U.S.</option>
                                                        <option value="Wallis and Futuna">Wallis and Futuna</option>
                                                        <option value="Western Sahara">Western Sahara</option>
                                                        <option value="Yemen">Yemen</option>
                                                        <option value="Zambia">Zambia</option>
                                                        <option value="Zimbabwe">Zimbabwe</option>
                                                    </Select>
                                                </div>
                                            </div>
                                            <div className="col2">
                                                <div className="form-field">
                                                    <label className="form-label" htmlFor>State/Province<span>*</span></label>
                                                    <Input
                                                        type="text"
                                                        id="state"
                                                        className="form-input"
                                                        name="state"
                                                        placeholder="NY"
                                                        value={bState}
                                                        onChange={onChangeBState}
                                                        validations={[required]}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-col2">
                                            <div className="col2">
                                                <div className="form-field">
                                                    <label className="form-label" htmlFor>City/Town<span>*</span></label>
                                                    <Input
                                                        type="text"
                                                        id="city"
                                                        className="form-input"
                                                        name="city"
                                                        placeholder="New York"
                                                        value={bCity}
                                                        onChange={onChangeBCity}
                                                        validations={[required]}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col2">
                                                <div className="form-field">
                                                    <label className="form-label" htmlFor>Zip/Postcode<span>*</span></label>
                                                    <Input
                                                        type="text"
                                                        id="zip"
                                                        className="form-input"
                                                        name="zip"
                                                        placeholder={10001}
                                                        value={bZipCode}
                                                        onChange={onChangeBZipCode}
                                                        validations={[required]}
                                                    />
                                                </div>
                                            </div>
                                            {isValid ? <div className="form-inlineMessage success" style={{display: 'block'}}><i
                                                className="far fa-check-circle"/> COD is available on {bZipCode}
                                            </div> : <div className="form-inlineMessage error" style={{display: 'block'}}><i
                                                className="fas fa-exclamation-circle"/> Please enter a valid pincode.
                                            </div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="checkout_colmnMn">
                                <div className="checkout_colmnWr">
                                    <h2>Payment Method</h2>
                                </div>
                                <div style={{display: 'inline-grid', padding: '6px', margin: '15px'}}>
                                    <label>
                                        <input type="radio" defaultChecked="checked" value={'cod'} onChange={evt => setPaymentMode(evt.target.value)} name="paymentMode" style={{margin: '10px'}}/> COD
                                    </label>
                                    <label>
                                        <input type="radio" value={'stripe'} onChange={evt => setPaymentMode(evt.target.value)} name="paymentMode" style={{margin: '10px'}}/> Stripe
                                    </label>
                                </div>
                            </div>
                            <div className="checkout_colmnMn">
                                <div className="checkout_colmnWr">
                                    <div className="checkout-orderSummary">
                                        <div className="orderSummary-header">
                                            <h2>Order Summary</h2>
                                            <Link className="checkout-header-link" to="/cart">Edit Checkout</Link>
                                        </div>
                                        <div className="orderSummary-body">
                                            <ul>
                                                {cartItems.map(product => <CheckoutItem product={product}/>)}
                                            </ul>
                                        </div>
                                        <div className="orderSummary-footer">
                                            <div className="orderSummary-subtotal">
                                                <span className="orderSummary-Item-label">Subtotal:</span>
                                                <span className="orderSummary-Item-value">${total}</span>
                                            </div>
                                            <div className="orderSummary-shipping">
                                                <span className="orderSummary-Item-label">Shipping:</span>
                                                <span className="orderSummary-Item-value">Free</span>
                                            </div>
                                            <div className="orderSummary-tax">
                                                <span className="orderSummary-Item-label">Tax:</span>
                                                <span className="orderSummary-Item-value">$0.00</span>
                                            </div>
                                            <div className="orderSummary-giftcoupon">
                                                <div className="redeemable-label">Coupon Code</div>
                                                <div className="redeemable-collapsable">
                                                    <div className="form-field">
                                                        <input name="redeemableCode" className="form-input" type="text"/>
                                                        <input className="btn-secondry" type="submit" defaultValue="Apply"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="orderSummary-total">
                                                <span className="orderSummary-Item-label">Total:</span>
                                                <span className="orderSummary-Item-value">${total}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {checkout ? <button type={'button'} className="checkout-cta">
                                        <Link to="/">Continue Shopping</Link>
                                    </button> :
                                    <div className="placeorder-btn">
                                        <button type="submit" className="btn-secondry" disabled={loading}>
                                            {loading && (
                                                <i className="fa fa-spinner fa-spin" aria-hidden="true"/>
                                            )}
                                            <span>Place Your Order</span>
                                        </button>
                                    </div>
                                }
                                <CheckButton style={{display: "none"}} ref={checkBtn}/>
                            </div>
                        </div>
                    </Form>
                </div>
            </section>
        </Layout>
    )
}
export default Checkout;
