import React, {useEffect, useState} from 'react';
import Layout, { siteURL } from "../MyLayout";
import {Link} from "react-router-dom";
import axios from "axios";
import authHeader from "../../services/auth-header";
import WishListItems from "./WishListItems";

const WishList = (props) => {
    const [allProducts, setAllProduct] = useState([]);
    useEffect(() => {
        callAPI();
    }, []);
    /**
     * Remove Wishlist
     * */
    const handelRemoveWishList = (item) => {
        axios.delete(`${siteURL}`+'deleteWishList/' + item.id, {
            headers: authHeader()
        }).then((response) => {
            callAPI();
        }).catch(function (response) {
            console.log(response);
        });
    }
    /**
     * Wishlist Product List
     * */
    const callAPI = () => {
        axios.get(`${siteURL}`+'GetWishList', {
            headers: authHeader()
        }).then((response) => {
            console.log('response.data', response.data);
            setAllProduct(response.data.data)
        })
            .catch(function (response) {
                console.log(response);
            });
    }
    return (
        <Layout>
            <section className="breadcrumbs">
                <div className="container">
                    <div className="breadcrumb">
                        <ul>
                            <li><Link to="/">Home</Link></li>
                            <li>Wishlist</li>
                        </ul>
                    </div>
                </div>
            </section>
            <section className="wishlist_pageMn">
                <div className="container">
                    <div className="wishlist_page">
                        <main className="wishlist-productsMn">
                            <div className="page_heading">
                                <h1>Wishlist</h1>
                            </div>
                            <div className="wishlist-products">
                                <div className="product_grid">
                                    <ul>
                                        {allProducts
                                        && allProducts.length
                                        && allProducts.map(item => {
                                            return <WishListItems items={item} removeWishList={handelRemoveWishList}/>
                                        })}
                                    </ul>
                                </div>
                            </div>
                            <div id="trueModal" className="cartmodel text-center">
                                <div className="swal2-header">
                                    <div className="swal2-icon"><i>!</i></div>
                                </div>
                                <div className="swal2-content">
                                    <p>Are you sure you want to delete this item?</p>
                                </div>
                                <div className="swal2-actions">
                                    <button className="btn btn-secondry btn-small">Ok</button>
                                    <button data-fancybox-close className="btn btn-primary btn-small">Cancel</button>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>
            </section>
        </Layout>
    );
}
export default WishList;
