import React from 'react';
import Header from "./Header";
import Footer from "./Footer";

const NotFound = () => {
    const center = {
        textAlign: 'center',
        marginTop: '100px'
    }
    return (
        <>
            <Header/>
            <div style={center}>
                <h1>404</h1>
                <p>This is the 404 Page.</p>
            </div>
            <Footer/>
        </>
    );
}
export default NotFound;
