import React, {useEffect, useState, useContext} from 'react';
import Layout from "../MyLayout";
import {Link} from "react-router-dom";
import {CartContext} from "../../contexts/CartContext";
import CartProducts from "./CartProducts";

function Cart(props) {
    const {cartItems, total} = useContext(CartContext);
    return (
        <Layout>
            <section className="breadcrumbs">
                <div className="container">
                    <div className="breadcrumb">
                        <ul>
                            <li><Link to="/">Home</Link></li>
                            <li>Cart</li>
                        </ul>
                    </div>
                </div>
            </section>
            <section className="cart_page_Mn">
                <div className="container">
                    <div className="page_heading">
                        <h1>Your Cart</h1>
                    </div>
                    <div className="cart-content">
                        <table className="cart" data-cart-quantity={2}>
                            <thead className="cart-header">
                            <tr>
                                <th className="cart-header-item" colSpan={2}>Item</th>
                                <th className="cart-header-item">Price</th>
                                <th className="cart-header-item cart-header-quantity">Quantity</th>
                                <th className="cart-header-item">Remove</th>
                            </tr>
                            </thead>
                            <tbody className="cart-list">
                            {
                                cartItems.length > 0 ?
                                    <CartProducts/> :
                                    <div className="p-3 text-center text-muted">
                                        Your cart is empty
                                    </div>
                            }
                            </tbody>
                        </table>
                    </div>
                    <div className="cart-totals">
                        <div className="cart-total">
                            <div className="cart-total-label">
                                <strong>Grand total:</strong>
                            </div>
                            <div className="cart-total-value cart-total-grandTotal">
                                <span>${total}</span>
                            </div>
                        </div>
                    </div>
                    <div className="cart-actions">
                        <Link className="button btn-primary" to="/products">Shop More</Link>
                        <Link className="button btn-secondry" to="/checkout" title="Click here to proceed to checkout">Check out</Link>
                    </div>
                </div>
            </section>
        </Layout>
    )
}
export default Cart;
